import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SingupAPi } from '../../util/encriptPass';

function Address() {
    const navigate = useNavigate();
    const [companyAdress1, setcompanyAdress1] = useState("");
  const [companyAdress2, setcompanyAdress2] = useState("");
  const [companyPostalCode, setcompanyPostalCode] = useState("");
  const [companyEINnUMBER, setcompanyEINnUMBER] = useState("");
  const [companYpHONE, setcompanYpHONE] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const props = location.state;

  // const id = GenerateCompanyID(props.name, props.email, companYpHONE);
  const data = {
    "Organization ID":props.id,
    "Name":props.name,
    "Email": props.email,
    "Password": props.password,
    "Country": props.country,
    "State": props.state,
    "City": props.city,
    'TimeZone': props.timezone,
      "Currency": props.currency ,
         "Address": companyAdress1+ " " +  companyAdress2,
         "Postalcode": companyPostalCode,
         "Einnumber": companyEINnUMBER,
         "Phone": companYpHONE,
        "Photo":"",
      "Status":"Active",
    "isNewUser":"true",
  "Locations":[],
  "Policies":[],
  "Timing":[],
  "WorkingDays":[],
  "LeavesAllowed": "0",
  "custom_settings": {},
  "users":[],
  'allowedDevices':[]
 }

 const postData = (Mydata) => {
  const url = SingupAPi();
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(Mydata),
  })
    .then(response => response.json())
    .then(data => {
      // Handle the response data here
      // console.log(data);
      const status = data["statusCode"]
      if (status === 200) {
        setLoading(false);
        const email = localStorage.removeItem('em'); 
      const pass = localStorage.removeItem('pa');
      const name = localStorage.removeItem('na');
        navigate("/signup/verified", { replace: true });
      
      }else{
        setLoading(false);
        alert ("Status is "+status +" Something went wrong. Could not signup right now. Try again leter")

      }
      
      
    }).then(data =>{
      
      // setComplete(true);

    })
    .catch(error => {
      // Handle errors here
      console.error('Error:', error);
    });
  // console.log("here =====> on function <=============")
};




    const OnFormclik = (e) =>{
        e.preventDefault();
        // console.log(data)
        setLoading(true);
        postData(data);
        
    
      }


      const previous = () =>{
        navigate(-1);

      }
      const steps = [
        { index: 1, description: "Company Info" },
        { index: 2, description: "OTP Verification" },
        { index: 3, description: "Subscription Selection" },
        { index: 4, description: "Location Details" },
        { index: 5, description: "Additional Info" }
      ];
  return (
    <div>
      <div className=" flex min-h-full flex-1 flex-col justify-center px-6 pt-[3rem] p-[2rem] -ml-[36rem] md:p-0 md:ml-0 lg:ml-0 md:py-12 lg:px-8">
      <div className="hidden md:flex items-center justify-center mb-7">
        {steps.map((step, index) => (
          <div key={step.index} className="relative">
            <div className={`h-10 w-10 flex items-center justify-center rounded-full border-2 mx-2 border-indigo-900 ${
              step.index === 5 ? 'bg-indigo-900 text-white' : 'text-indigo-900'
            }`}>
              {step.index}
            </div>
            <p className={`text-indigo-900 mt-2 text-sm mx-2  ${step.index === 5 && 'font-bold'}`}>{step.description}</p>
            {index !== steps.length - 1 && (
              <div className="absolute top-5 left-14 h-px w-14 bg-indigo-900 mx-3"></div>
            )}
          </div>
        ))}
      </div>
        <div className="mt-[rem] md:mt- sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={ OnFormclik }>
            <div>
              <label
                htmlFor="email"
                className="ml-[20.5rem] w-[10rem] md:ml-0 block text-sm md:text-sm font-medium leading-6 text-indigo-900"
              ></label>
              <div className="mt-2">
                <input
                  placeholder="Address Line 1"
                  id="Name"
                  name="Name"
                  type="Name"
                  autoComplete="Name"
                  required
                  value={companyAdress1}
                  onChange={(e) => setcompanyAdress1(e.target.value)}
                  className="block  ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border border-gray-300 py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-indigo-900  sm:text-sm sm:leading-6"
                />
                <p className="text-sm pl-[20.5rem] w-[36rem] md:w-[15rem]  md:pl-2 mt-1 md:mt-1 text-gray-400 md:-mb-[0.5rem]">
                  Where Company Legally Based?
                </p>
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="ml-[20.5rem] w-[10rem] md:ml-0 block text-sm md:text-sm font-medium leading-6 text-indigo-900 mt-5"
              ></label>
              <div className="mt-2">
                <input
                  placeholder="Address Line 2 (Optional)"
                  id="Name"
                  name="Name"
                  type="Name"
                  autoComplete="Name"
                  value={companyAdress2}
                  onChange={(e) => setcompanyAdress2(e.target.value)}
                  className="block  ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border border-gray-300 py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-indigo-900  sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between mt-2">
                <label
                  htmlFor="password"
                  className="ml-[20.5rem]  md:ml-0 block text-sm md:text-sm font-medium leading-6 text-indigo-900"
                ></label>
              </div>
              <div className="">
                <input
                  placeholder="Postal Code"
                  id="Code"
                  name="Code"
                  type="Code"
                  autoComplete="current-Code"
                  required
                  value={companyPostalCode}
                  onChange={(e) => setcompanyPostalCode(e.target.value)}
                  className="block  ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border border-gray-300 py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-indigo-900 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="phone"
                className="ml-[20.5rem] w-[10rem] md:ml-0 block text-sm md:text-sm font-medium leading-6 text-indigo-900"
              ></label>
              <div className="mt-2 ">
                <input
                  placeholder="EIN number (optional)

                        "
                  id="phone"
                  name="phone"
                  type="phone"
                  autoComplete="phone"
                  value={companyEINnUMBER}
                  onChange={(e) => setcompanyEINnUMBER(e.target.value)}
                  className="block  ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border border-gray-300 py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-indigo-900 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="flex mt-2">
              {" "}
              <input
                placeholder="Company Phone Number"
                id="phone"
                name="phone"
                type="phone"
                autoComplete="phone"
                required
                value={companYpHONE}
                onChange={(e) => setcompanYpHONE(e.target.value)}
                className="block  ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border border-gray-300 py-2 pl-[0.75rem] pr-[8.5rem] md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-indigo-900 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
              />
            </div>

            <div className="relative text-indigo-900 pl-[20.5rem] w-[40rem] md:w-[26rem]  md:pl-2 md:ml-[0rem] lg:-ml-[rem] mt-8">
              <label className="text-indigo-900 ">
                <input type="checkbox" required />
                &nbsp;I accept the Terms of Services and I'm authorized to
                accept for my company
              </label>
            </div>
            <div className="mt-5 button-container flex flex-col items-center justify-center md:ml-0 md:flex-row md:w-full ml-[20rem] w-[19rem] md:mt-[1rem] lg:mt-[2rem] mb-[2rem] space-y-2 md:space-y-0">
            {/* w-[19rem] md:w-full ml-[20rem] md:ml-0 */}
            <button
                className="w-[19rem] md:w-[11.5rem] flex py-3 px-[3rem] md:px-[0rem] md:py-3 justify-center  rounded-md bg-indigo-900 text-sm md:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:none"
                onClick={previous}
              >
                Previous
              </button>
              <button
              type="submit"
                className={`transition-all duration-400 ease-in-out ml-3  ${
                  loading
                    ? 'w-14 h-14 bg-indigo-900 text-white rounded-full'
                    : 'flex w-full justify-center rounded-md bg-indigo-900 px-3 py-3 md:py-3 text-base md:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:none'
                } hover:bg-indigo-700`}
                // onClick={MoveTODashboard}
                disabled={loading}
              >
                {loading ? (
                  <div className=" animate-spin flex items-center justify-center ">
                    <svg
                      className="w-6 h-6 text-white "
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                     
                  <circle
                    className='opacity-25 '
                    cx='12'
                    cy='12'
                    r='10'
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>

                    </svg>
                  </div>
                ) : (
                  'Sign up'
                )}
              </button>
              </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Address
