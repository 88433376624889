import React from 'react';
import { Link } from 'react-router-dom';
import myImg from '../images/international-Teams.png'

 function hero() {
  return ( <div className="">
  <div className="px-5 md:px-10 ">
    <div className="mx-auto w-full max-w-8xl ">
      <div className="py-16 md:py-24 lg:py-32 xl:ml-[10rem] ">
        <div className="">
          <div className="">
            <div className="">
              <div className="grid grid-cols-2 max-[991px]:flex max-[991px]:flex-col max-[991px]:gap-y-16 ">
              
                <div className="w-full p-4 max-[991px]:max-w-[720px] sm:w-full sm:p-6 md:p-10 lg:w-[600px] md:mt-[10rem] lg:mt-0 lg:-ml-[4rem]">
                  <div className="mb-4">
                    <h2 className="text-indigo-900 text-4xl font-bold md:text-7xl">Build your international team with ease</h2>
                  </div>
                  <div className="mb-12">
                    <div className="flex flex-row items-center gap-x-4 max-[479px]:flex-row max-[479px]:justify-start max-[479px]:gap-y-[0px] sm:items-center">
                      <p className="text-xl text-indigo-900">Find the best talent – hire, manage, and pay them all with Token Talent</p>
                    </div>
                  </div>
                  <Link to="/features" className="inline-block cursor-pointer border border-solid  bg-indigo-900 px-6 py-4 text-center text-white font-bold  transition hover:border-black hover:bg-white hover:text-indigo-900 rounded-full mr-5 md:mr-6 lg:mr-8">See Differences</Link>
                </div>
                <div className=" w-full max-[991px]:items-center max-[991px]:justify-center sm:w-full lg:w-[618px] ">
                  <img alt="A serene beach scene with palm trees, white sand, and crystal clear blue water under a clear blue sky." src={myImg} className="mx-auto   w-full max-w-none object-cover  lg:max-w-[500px] rounded-2xl" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> );
 }
 
 export default hero;