import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import PricingPage from '../pricing/PricingPage';

function Subcription() {
    const location = useLocation();
    const navigate = useNavigate();
    const props = location.state;
    const steps = [
      { index: 1, description: "Company Info" },
      { index: 2, description: "OTP Verification" },
      { index: 3, description: "Subscription Selection" },
      { index: 4, description: "Location Details" },
      { index: 5, description: "Additional Info" }
    ];
    // console.log(props.email);
  return (
    <div className=' '>
       
            <p className='text-center mb-5 ml-5 mr-5 '>Please select the subscription you find best for your company and subscribe</p>
            <div className="hidden md:flex items-center justify-center mb-7">
        {steps.map((step, index) => (
          <div key={step.index} className="relative">
            <div className={`h-10 w-10 flex items-center justify-center rounded-full border-2 mx-2 border-indigo-900 ${
              step.index === 3 ? 'bg-indigo-900 text-white' : 'text-indigo-900'
            }`}>
              {step.index}
            </div>
            <p className={`text-indigo-900 mt-2 text-sm mx-2  ${step.index === 3 && 'font-bold'}`}>{step.description}</p>
            {index !== steps.length - 1 && (
              <div className="absolute top-5 left-14 h-px w-14 bg-indigo-900 mx-3"></div>
            )}
          </div>
        ))}
      </div>
            <PricingPage email={props.email} id={props.id}/>
            

         </div>
  )
}  

export default Subcription
