import React, { useState } from "react";
import { Link } from 'react-router-dom';
import FDashboard from '../images/NewDashboard.png';
import EmpImg from '../images/employeeapp.png'
import { BsFillPersonVcardFill } from "react-icons/bs";
import { BsFillClockFill } from "react-icons/bs";
import { BsFillDoorOpenFill } from "react-icons/bs";
import { BsCurrencyDollar } from "react-icons/bs";
import { BsFillCalendar2DateFill } from "react-icons/bs";
import { BsFillSignpost2Fill } from "react-icons/bs";
import { PiShareNetworkFill
} from "react-icons/pi";

import { MdMilitaryTech } from "react-icons/md";
import { FaCreativeCommonsShare
} from "react-icons/fa6";


import { BsFillPersonFill } from "react-icons/bs";

import { FaBilibili } from "react-icons/fa6";

import Macbook from '../images/macbook.png';

import Navbar from "./navbar";
import Footer from "./footer";

export default function Example() {
  const [showMoreFAQs, setShowMoreFAQs] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <Navbar />

      <div class="bg-blue-100 px-5 md:px-10">
        <div class=" pt-[8rem] pb-[0rem]">
          <div class="mx-auto w-full max-w-3xl mb-8 md:mb-12 lg:mb-16">
            <div class="text-center">
              <h2 class="font-bold text-3xl md:text-5xl text-indigo-900">
              Devote yourself to your most valuable creation





              </h2>
              <div class="mx-auto mt-4 max-w-[528px] mb-8 md:mb-12 lg:mb-16"></div>
              <div class="flex justify-center">
                <div class="mr-6 md:mr-10 lg:mr-12">
                  <h3 class="font-bold text-[#276ef1] text-2xl md:text-3xl">
                    100k+
                  </h3>
                  <p class="text-sm text-[#636262]">Employees</p>
                </div>
                <div class="">
                  <h3 class="font-bold text-[#276ef1] text-2xl md:text-3xl">
                    20k+
                  </h3>
                  <p class="text-sm text-[#636262]">Payroll Run/Month</p>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-auto w-full max-w-7xl">
            <img
              src={FDashboard}
              alt=""
              class="inline-block h-full w-full max-w-full object-cover"
            />
          </div>
        </div>
      </div>
      <div className="relative max-[479px] overflow-hidden">
        <div className="px-5 md:px-10">
          <div className="mx-auto w-full max-w-7xl">
            <div className="py-16 md:py-24 lg:py-32">
              <div className="flex flex-col gap-y-0 sm:gap-y-12 lg:gap-y-40">
                <div className="grid flex-row items-center gap-4 max-[991px] grid-cols-1 lg:grid-cols-[1fr_minmax(200px,_1fr)]">
                  <div className="w-full p-4 max-[991px] max-w-[720px] sm:w-full sm:p-6 md:p-10 lg:w-[600px]">
                    <div className="mb-4">
                      <div className="max-[479px] max-w-[400px]">
                        <h2 className="text-3xl font-semibold md:text-5xl">
                          Why Choose{" "}
                          <span className="text-indigo-900">Token Talent?</span>
                        </h2>
                      </div>
                    </div>
                    <div className="mb-12">
                      <div className="max-w-[400px] sm:max-w-[480px]">
                        <p className="text-lg text-indigo-900">
                          {" "}
                          At Token Talent, we understand that finding the right
                          talent for your organization or the perfect job
                          opportunity can be a challenging task. That’s why
                          we’ve developed a platform that sets us apart from the
                          rest. With our commitment to excellence and
                          innovation, we’re here to make your talent journey
                          seamless and successful.
                          <br />
                          <br />
                          <span className="font-bold">
                            Stay on top of your tasks like never before.
                          </span>
                        </p>
                      </div>
                    </div>
                    <Link
                      to="/signup"
                      className="inline-block cursor-pointer items-center self-center rounded-[80px] hover:text-indigo-900 px-6 text-center text-lg font-bold border-black text-white  bg-indigo-900 no-underline transition hover:bg-white hover:outline-0 py-3"
                    >
                      Get Started
                    </Link>
                  </div>
                  <div className="h-[416px] w-full max-[991px] items-center justify-center sm:w-full lg:w-[618px]">
                    <img
                      src="https://assets.website-files.com/64fb4d49d44af6ffd057ea86/65008999824e9f9cb24a197a_Smart%20Management.svg"
                      alt=""
                      className="mx-auto inline-block h-full w-full max-w-[480px] object-fill md:max-w-[640px] md:object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>
            <img
              src="https://assets.website-files.com/64fb4d49d44af6ffd057ea86/64fde39425f96a17e11e5331_BG%20(1).svg"
              alt=""
              className="absolute -left-2/4 bottom-[auto] right-[auto] top-[32%] -z-[1] inline-block max-w-full sm:-left-2/4 sm:top-[30%] lg:left-[-20%] lg:top-[25%]"
            />
          </div>
        </div>
      </div>

      <div className="bg-blue-100 py-24 sm:py-32 ">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className=" p-[2.5rem] mx-auto max-w-2xl lg:text-center mb-[5rem] -mt-20">
            <h2 className="text-3xl md:text-5xl  font-bold leading-13 text-indigo-900 ">
              Token Talent's Unique Features{" "}
            </h2>
          </div>
          <section className="block">
            <div className="px-5 md:px-10 -mt-10">
              <div className="mx-auto w-full max-w-7xl">
                <div className="py-16 md:py-24 lg:py-15">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 md:gap-4 lg:gap-6 justify-items-center sm:justify-items-stretch">
                    {/* Repeat this block for each item */}
                    <div className="grid-cols-1 grid gap-6 border border-solid border-[#cdcdcd] bg-white rounded-md p-8 md:p-10">
                    <div className="w-16 h-16 bg-indigo-600 rounded-full flex items-center justify-center">
                  <PiShareNetworkFill
 className="text-white h-6 w-6" />
                </div>
                      <div className="text-xl font-semibold">
                        Unparalleled Talent Network
                      </div>
                      <div className="text-[#636262] text-sm sm:text-sm">
                        Quality Over Quantity: We take pride in curating a
                        network of top-tier talent. We believe in quality over
                        quantity, ensuring that every candidate you encounter is
                        exceptional
                      </div>
                    </div>
                    <div className="grid-cols-1 grid gap-6 border border-solid border-[#cdcdcd] bg-white rounded-md p-8 md:p-10">
                    <div className="w-16 h-16 bg-indigo-600 rounded-full flex items-center justify-center">
                  <MdMilitaryTech className="text-white h-8 w-8" />
                </div>
                      <div className="text-xl font-semibold">
                        Cutting-Edge Technology
                      </div>
                      <div className="text-[#636262] text-sm sm:text-sm">
                        Efficiency & Precision: Our advanced AI-driven tools
                        streamline the recruitment process, saving you time and
                        effort while ensuring precision in talent matching.
                      </div>
                    </div>
                    <div className="grid-cols-1 grid gap-6 border border-solid border-[#cdcdcd] bg-white rounded-md p-8 md:p-10">
                    <div className="w-16 h-16 bg-indigo-600 rounded-full flex items-center justify-center">
                  <BsFillPersonFill className="text-white h-6 w-6" />
                </div>
                      <div className="text-xl font-semibold">
                        Personalized Experience
                      </div>
                      <div className="text-[#636262] text-sm sm:text-sm">
                        Tailored Matches: We understand that every organization
                        and individual is unique. That’s why we provide
                        personalized recommendations, so you can find the right
                        match every time.
                      </div>
                    </div>
                    <div className="grid-cols-1 grid gap-6 border border-solid border-[#cdcdcd] bg-white rounded-md p-8 md:p-10">
                    <div className="w-16 h-16 bg-indigo-600 rounded-full flex items-center justify-center">
                  <FaBilibili className="text-white h-6 w-6" />
                </div>
                      <div className="text-xl font-semibold">
                        Comprehensive Resources
                      </div>
                      <div className="text-[#636262] text-sm sm:text-sm">
                        Expert Insights: Access to industry experts, career
                        resources, and educational content to help you stay
                        ahead in today’s competitive landscape.
                      </div>
                    </div>
                    <div className="grid-cols-1 grid gap-6 border border-solid border-[#cdcdcd] bg-white rounded-md p-8 md:p-10">
                    <div className="w-16 h-16 bg-indigo-600 rounded-full flex items-center justify-center">
                  <FaCreativeCommonsShare
 className="text-white h-6 w-6" />
                </div>
                      <div className="text-xl font-semibold">
                        Commitment to Diversity and Inclusion
                      </div>
                      <div className="text-[#636262] text-sm sm:text-sm">
                        Inclusive Platform: Token Talent is dedicated to
                        fostering diversity and inclusion in the workplace. We
                        actively support equal opportunities for all.
                      </div>
                    </div>
                    {/* End of repeated block */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className=" p-[2.5rem] mx-auto max-w-2xl lg:text-center mb-[5rem]">
        <h2 className="text-2xl font-bold leading-7 text-indigo-900 mt-20">
          Join Token Talent Today
        </h2>
        <p className="mt-2 text-base  tracking-tight text-indigo-900 sm:text-lg">
          Ready to take your talent journey to the next level? Join Token Talent
          and experience the difference. Whether you’re an employer seeking
          exceptional talent or an individual looking for the perfect
          opportunity, we’re here to make it happen. Discover the possibilities
          with Token Talent. Your success is our mission.
        </p>
        
      </div>

      <div className="bg-blue-100 relative max-[479px] overflow-hidden">
        <div className="px-5 md:px-10">
          <div className="mx-auto w-full max-w-7xl">
            <div className="py-16 md:py-24 lg:py-32">
              <div className="flex flex-col gap-y-0 sm:gap-y-12 lg:gap-y-40">
                <div className="grid flex-row items-center gap-4 max-[991px] grid-cols-1 lg:grid-cols-[1fr_minmax(200px,_1fr)]">
                  <div className="h-[416px] w-full max-[991px] items-center justify-center sm:w-full lg:w-[618px]">
                    <img
                      src="https://assets.website-files.com/64fb4d49d44af6ffd057ea86/650089ec401b51014f33ffa6_Team%20Collaboration.svg"
                      alt=""
                      className="mx-auto inline-block h-full w-full max-w-[480px] object-fill md:max-w-[640px] md:object-cover"
                    />
                  </div>
                  <div className="w-full p-4 max-[991px] max-w-[720px] sm:w-full sm:p-6 md:p-10 lg:w-[600px]">
                    <div className="mb-4">
                      <div className="max-[479px] max-w-[400px]">
                        <h2 className="text-3xl font-semibold md:text-5xl">
                          <span className="text-indigo-900">
                            Team Collaboration
                          </span>{" "}
                          Made Easy
                        </h2>
                      </div>
                    </div>
                    <div className="mb-12">
                      <div className="max-w-[400px] sm:max-w-[480px]">
                        <p className="text-lg text-indigo-900">
                          {" "}
                          Streamline communication and collaboration within your
                          team. Our platform facilitates real-time interaction,
                          file sharing, and progress tracking, ensuring everyone
                          stays on the same page.
                          <br />
                          <br />
                          <span className="font-bold">
                            Collaborate seamlessly for maximum efficiency.
                          </span>
                        </p>
                      </div>
                    </div>
                    <Link
                      to="/signup"
                      className="inline-block cursor-pointer items-center self-center rounded-[80px] hover:text-indigo-900 px-6 text-center text-lg font-bold border-black text-white  bg-indigo-900 no-underline transition hover:bg-white hover:outline-0 py-3"
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="https://assets.website-files.com/64fb4d49d44af6ffd057ea86/64fde39425f96a17e11e5331_BG%20(1).svg"
              alt=""
              className="absolute -left-2/4 bottom-[auto] right-[auto] top-[32%] -z-[1] inline-block max-w-full sm:-left-2/4 sm:top-[30%] lg:left-[-20%] lg:top-[25%]"
            />
          </div>
        </div>
      </div>

      <div className="bg-white px-5 md:px-10 ">
        <div className="mx-auto w-full max-w-7xl">
          <div className="py-16 md:py-24 lg:py-32">
            <div className="grid items-center max-[991px] justify-items-start grid-cols-1 lg:grid-cols-2 gap-8 sm:gap-20">
              <div className="flex-col flex items-center justify-center bg-blue-100 p-6 max-[991px] grid-area:2/1/3/2 rounded-xl">
                <img
                  src={EmpImg}
                  alt="Features Image"
                  className="mx-auto inline-block h-full w-full max-w-[640px] object-cover rounded-2xl"
                />
              </div>
              <div className="max-[991px] max-w-[720px]">
                <h2 className="text-indigo-900 mb-4 mt-6 font-extrabold text-3xl md:text-5xl pb-4">
                  Simplify Payment Process for Employees
                </h2>
                <div className="max-w-[528px] mb-6 md:mb-10 lg:mb-12">
                  <p className="text-xl text-indigo-900">
                    Enjoy a smooth transactional experience with our app -
                    effortlessly make payments, eliminating all hassles.
                    Streamline your financial activities and relish the
                    convenience of making payments without stress or
                    inconvenience
                  </p>
                </div>
                <div className="flex items-center">
                  <Link
                    to="/pricing"
                    className="inline-block cursor-pointer border border-solid text-white bg-indigo-900 px-6 py-4 text-center font-bold  transition hover:border-black hover:bg-white hover:text-indigo-900 rounded-full mr-5 md:mr-6 lg:mr-8"
                    onClick={scrollToTop}>
                    Learn More
                  </Link>
                  <a
                    href="#"
                    className="flex-row flex max-w-full p-0 font-bold"
                  >
                    {" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="block bg-blue-100">
        <div className="px-5 md:px-10">
          <div className="mx-auto w-full max-w-7xl">
            <div className="py-16 md:py-24 lg:py-32">
              <div className="mx-auto max-w-3xl text-center">
                <h2 className="text-indigo-900 mb-4 mt-6 font-extrabold text-3xl md:text-5xl">
                  Prioritize user needs at every step
                </h2>
                <div className="mx-auto mt-4 max-w-[528px] mb-8 md:mb-12 lg:mb-16"></div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 md:gap-4 lg:gap-6 justify-items-center sm:justify-items-stretch">
                <div className="grid-cols-1 grid gap-6 border border-[#cdcdcd] bg-white transition hover:shadow-md rounded-xl p-8 md:p-10">
                <div className="w-16 h-16 bg-indigo-600 rounded-full flex items-center justify-center">
                  <BsFillPersonVcardFill className="text-white h-6 w-6" />
                </div>

                  {/* <img
                    src="https://uploads-ssl.webflow.com/646f65e37fe0275cfb808405/646f66cdeeb4ddfdae25a287_Circle%20Image.svg"
                    alt="Features Icon"
                    className="inline-block max-w-full h-16"
                  /> */}
                  <div className="text-xl font-semibold">
                    Profile Administration
                  </div>
                  <div className="text-sm text-[#636262]">
                    Efficient management of employee profiles and related
                    information.
                  </div>
                </div>
                <div className="grid-cols-1 grid gap-6 border border-[#cdcdcd] bg-white transition hover:shadow-md rounded-xl p-8 md:p-10">
                <div className="w-16 h-16 bg-indigo-600 rounded-full flex items-center justify-center">
                  <BsFillClockFill className="text-white h-6 w-6" />
                </div>
                  <div className="text-xl font-semibold">
                    Employee Attendance and Time Tracking
                  </div>
                  <div className="text-sm text-[#636262]">
                    Accurate tracking of employee attendance and work hours.
                  </div>
                </div>
                <div className="grid-cols-1 grid gap-6 border border-[#cdcdcd] bg-white transition hover:shadow-md rounded-xl p-8 md:p-10">
                <div className="w-16 h-16 bg-indigo-600 rounded-full flex items-center justify-center">
                  <BsFillDoorOpenFill className="text-white h-6 w-6" />
                </div>
                  <div className="text-xl font-semibold">
                    Leave Administration
                  </div>
                  <div className="text-sm text-[#636262]">
                    Streamlined process for managing employee leave requests.
                  </div>
                </div>
                <div className="grid-cols-1 grid gap-6 border border-[#cdcdcd] bg-white transition hover:shadow-md rounded-xl p-8 md:p-10">
                <div className="w-16 h-16 bg-indigo-600 rounded-full flex items-center justify-center">
                  <BsFillCalendar2DateFill className="text-white h-6 w-6" />
                </div>
                  <div className="text-xl font-semibold">
                    Holiday Scheduling
                  </div>
                  <div className="text-sm text-[#636262]">
                    Structured scheduling and management of employee holidays.
                  </div>
                </div>
                <div className="grid-cols-1 grid gap-6 border border-[#cdcdcd] bg-white transition hover:shadow-md rounded-xl p-8 md:p-10">
                <div className="w-16 h-16 bg-indigo-600 rounded-full flex items-center justify-center">
                  <BsCurrencyDollar className="text-white h-6 w-6" />
                </div>
                  <div className="text-xl font-semibold">Salary Records</div>
                  <div className="text-sm text-[#636262]">
                    Systematic recording and access to employee salary
                    information.
                  </div>
                </div>
                <div className="grid-cols-1 grid gap-6 border border-[#cdcdcd] bg-white transition hover:shadow-md rounded-xl p-8 md:p-10">
                <div className="w-16 h-16 bg-indigo-600 rounded-full flex items-center justify-center">
                  <BsFillSignpost2Fill className="text-white h-6 w-6" />
                </div>
                  <div className="text-xl font-semibold">
                    Employee Exit Protocols
                  </div>
                  <div className="text-sm text-[#636262]">
                    Guidelines and procedures for employee departure and
                    termination.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="block bg-[#eef0f4]">
        <div className="px-5 md:px-10">
          <div className="mx-auto w-full max-w-7xl">
            <div className="py-16 md:py-24 lg:py-32">
              <div className="flex h-auto grid-cols-1 flex-wrap justify-center gap-12 overflow-visible object-fill px-8 text-left max-[991px]:flex-col max-[479px]:flex lg:grid-cols-2 lg:gap-20">
                <div className="flex-[1_1_500px] max-[991px]:w-full max-[991px]:flex-none">
                  <div className="mb-8 max-w-3xl md:mb-12 lg:mb-16">
                    <h2 className="text-indigo-900 text-3xl font-bold md:text-5xl">
                      General FAQs
                    </h2>
                    <div className="ml-0 mr-0 mt-4 max-w-[528px]">
                      <p className="text-[#636262]">
                      If you can't find what you're looking for, feel free to reach out to our dedicated support team for personalized assistance</p>
                    </div>
                  </div>
                  <div className="mb-6 md:h-[280px] w-full flex-none overflow-hidden rounded-xl bg-white p-8">
                    <div className="flex flex-row gap-4">
                      <img
                        src={Macbook}
                        alt="Support image of Author"
                        className="inline-block h-12 w-12 max-w-full rounded-full object-cover"
                      />
                      <div className="flex flex-col gap-[6px]">
                        <h5 className="text-indigo-900 text-base font-bold md:text-xl">
                          Still have questions?
                        </h5>
                        <div className="max-w-[380px]">
                          <p className="text-[#636262]">
                            Can’t find the answer you’re looking for? Please
                            chat to Token Talent Team
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mb-6 mt-8 h-[0.5px] w-full bg-[#a6a6a6]"></div>
                    <Link
                      to="/pricing"
                      className="inline-block cursor-pointer border border-solid text-white bg-indigo-900 px-6 py-4 text-center font-bold  transition hover:border-black hover:bg-white hover:text-indigo-900 rounded-full mr-5 md:mr-6 lg:mr-8"
                      onClick={scrollToTop}>
                      Learn More
                    </Link>
                  </div> 
                </div>
                <div className="flex-[1_1_500px] max-[991px]:w-full max-[991px]:flex-none">
                  <div className="space-y-4 lg:-mt-[9.5rem]">
                  <section className="block">
        <div className="px-5 md:px-10">
          <div className="mx-auto w-full max-w-5xl">
            <div className="py-16 md:py-24 lg:py-32">
              <div className="mx-auto max-w-3xl text-center mb-8 md:mb-12 lg:mb-16">
                <h2 className=" text-indigo-900 mb-4 mt-6 font-extrabold text-3xl md:text-5xl">
                  Frequently Asked Questions
                </h2>
                <div className="mx-auto mt-4 max-w-[528px]">
                  <p className="text-[#636262]">
                  Check out our FAQs. Our team is happy to answer any further questions you may have. 
                  </p>
                </div>
                
              </div>
              <div className="space-y-4">
              <details className="group [&_summary::-webkit-details-marker]:hidden">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900 lg:-mt-[2.7rem]"
    >
      <h2 className="font-medium">
      How long does onboarding take?     </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <p className="mt-4 px-4 leading-relaxed text-gray-700">
    Onboarding is almost immediate after you have access. If you have more than 10 employees and need help with the onboarding process, please reach out our customer success team onboarding@tokentant.com with your request.    </p>
  </details>

  <details className="group [&_summary::-webkit-details-marker]:hidden">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900"
      >
      <h2 className="font-medium">
      How is my data secured?      </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <p className="mt-4 px-4 leading-relaxed text-gray-700">
    Token Talent is built by a top tier technological international team with over decades of experience in building fintech products. Token Talent encrypts data at rest in AWS using AES-256 key encryption. Token Talent uses Amazon Web Services (AWS) to host its production servers, databases, and supporting services. As a global company, Token Talent uses a multi-region setup for its infrastructure.
    </p>
  </details>
  <details className="group [&_summary::-webkit-details-marker]:hidden">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900"
      >
      <h2 className="font-medium">
      How does Token Talent run direct deposit?      </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <p className="mt-4 px-4 leading-relaxed text-gray-700">
    Token Talent runs direct deposits with either international payment offerings or local ones. You choose whatever works best for you!
    </p>
  </details>
  <details className="group [&_summary::-webkit-details-marker]:hidden">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900"
      >
      <h2 className="font-medium">
      How do I reach customer support?     </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <p className="mt-4 px-4 leading-relaxed text-gray-700">
    Please reach out to us via chat on this website or email support@Token Talent.com.
    </p>
  </details>
</div>
            </div>
          </div>
        </div>
      </section>
                  </div>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

     

      <Footer />
    </>
  );
}
