import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signUpUserWithDetails } from '../../util/Signup';
import { EncryptionAndDecryptionKeys, encriptmydata } from '../../util/encriptPass';
import { getCurrentDate } from '../../util/date';
import { GenerateCompanyID } from '../../GenIdFunc';


function StepTwo(){
  const [otp, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, showError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const props = location.state;

  const confirmOtp = () =>{
    if (otp === props.otp){
      return true;
    }else{
      return false;
  }  
}
const steps = [
  { index: 1, description: "Company Info" },
  { index: 2, description: "OTP Verification" },
  { index: 3, description: "Subscription Selection" },
  { index: 4, description: "Location Details" },
  { index: 5, description: "Additional Info" }
];

const id = GenerateCompanyID(props.name, props.email, props.password);
const encriptDataAndSend = ()=>{
  const key = EncryptionAndDecryptionKeys();
  const encriptedEmail = encriptmydata(props.email, key); 
  const encriptedPass = encriptmydata(props.password, key);    
  const encriptedName = encriptmydata(props.name, key);
  const encriptedId = encriptmydata(id, key);
  localStorage.setItem('na', encriptedName);  
  localStorage.setItem('pa', encriptedPass);
  localStorage.setItem('em', encriptedEmail);
  localStorage.setItem('i', encriptedId);

}  

const signUpfromHere = async (e) => {
  setLoading(true);
  e.preventDefault();
  const data = {
    "id": id,
    "email":props.email,
    "company":props.name,
    "password":props.password,
    'payment':'un verified',
    'date':getCurrentDate()
  }
  const signupStatus = await signUpUserWithDetails({data});
  if (signupStatus === true) {
    handleSubmit();
  }else{
    showError(true);
  }

} 


  const handleSubmit = (e)=>{
    // e.preventDefault();
    
    showError(false);
    const status = confirmOtp();
    if (status === true) {
      setLoading(false);
      encriptDataAndSend();
      navigate("/signup/subscription",{state: {name:props.name, email:props.email, password:props.password , id: id} });
    } else {
      setLoading(false);
      showError(true);
      console.log("its false")
      
    }

  }
  return (
    <div className=''>
      <div className=" flex min-h-full flex-1 flex-col justify-center px-6 pt-[3rem] p-[2rem] -ml-[36rem] md:p-0 md:ml-0 lg:ml-0 md:py-12 lg:px-8">
      <div className="hidden md:flex items-center justify-center mb-7">
        {steps.map((step, index) => (
          <div key={step.index} className="relative">
            <div className={`h-10 w-10 flex items-center justify-center rounded-full border-2 mx-2 border-indigo-900 ${
              step.index === 2 ? 'bg-indigo-900 text-white' : 'text-indigo-900'
            }`}>
              {step.index}
            </div>
            <p className={`text-indigo-900 mt-2 text-sm mx-2  ${step.index === 2 && 'font-bold'}`}>{step.description}</p>
            {index !== steps.length - 1 && (
              <div className="absolute top-5 left-14 h-px w-14 bg-indigo-900 mx-3"></div>
            )}
          </div>
        ))}
      </div>
        <div className="mt-[rem] md:mt- sm:mx-auto sm:w-full sm:max-w-sm">
          <p className='text-xs text-gray-600 text-center w-[20rem] md:w-full ml-[20rem] md:ml-0'>We've just send you a email with verification code. Please enter the verification code below</p>
        <form onSubmit={signUpfromHere}>
            <div>
              <label
                htmlFor="otp"
                className="ml-[20.5rem] w-[10rem] md:ml-0 block text-sm md:text-sm font-medium leading-6 text-indigo-900"
              ></label>
              <div className="mt-2 ">
                <input
                  placeholder="Enter Your OTP Here "
                  id="otp"
                  name="otp"
                  type="phone"
                  value={otp}
                  onChange={(e) => setOTP(e.target.value)}
                  autoComplete="phone"
                  required
                  className="block  ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border border-gray-300 py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-indigo-900 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                />
              </div>
              {error ? (<div className='text-red-500'>
                <p className='text-xs mt-2'>Something went wrong! Please check and try again</p>
                
              </div>):(<div></div>)}
              <div className="flex flex-col items-center justify-center  w-[19rem] md:w-full ml-[20rem] md:ml-0 mt-10 text-center">
          <button
              type="submit"
                className={`transition-all duration-400 ease-in-out ml-3  ${
                  loading
                    ? 'w-14 h-14 bg-indigo-900 text-white rounded-full'
                    : 'mt-2  w-[19rem] md:w-[11.5rem] flex py-3 px-[3rem] md:px-[0rem] md:py-3 justify-center rounded-md bg-indigo-900 text-sm md:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:none md:flex-none md:-mt-[0rem] md:ml-0 lg:ml-2'
                } hover:bg-indigo-700`}
                // onClick={MoveTODashboard}
                disabled={loading}
              >
                {loading ? (
                  <div className=" animate-spin flex items-center justify-center ">
                    <svg
                      className="w-6 h-6 text-white "
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                     
                  <circle
                    className='opacity-25 '
                    cx='12'
                    cy='12'
                    r='10'
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>

                    </svg>
                  </div>
                ) : (
                  'Continue'
                )}
              </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default StepTwo
