import { signUpUrl } from "../url";

export async function signUpUserWithDetails ({data}) {
    const url = signUpUrl();
    // console.log(data);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const mydata = await response.json();
        const status = mydata["statusCode"];
        // console.log(mydata)
        if (status == "200") {
            return true;
        }else{
            return false;

        }
        
        
      } 
    } catch (error) {
      console.error('Error fetching details:', error);
      return false;
    } 
  };