import React, { useState, useEffect } from "react";
import IMG from '../images/sided.png'

function Payroll() {
  const words = [
    "Thai? เงินเดือน",
    "Spanish? nómina",
    "French? paie",
    "German? Lohnabrechnung",
  ]; // Replace with your list of languages
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1500); // Change words every 1.5 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, []);

  const [currentLanguage, currentTranslation] =
    words[currentWordIndex].split("?");

  return (
    <div className="bg-blue-200  w-screen lg:h-[89rem] md:w-screen  2xl:w-screen lg:w-screen">
      <div className="p-[14rem] pl-20 md:p-[14rem] md:pl-20 lg:p-[14rem] xl:pl-20 lg:pl-0 2xl:pl-[4rem] 2xl:pb-[2rem]">
        <h2 className="text-6xl text-left w-[22rem] font-bold text-indigo-900 -ml-[4rem] md:text-7xl md:font-bold md:text-indigo-900 md:ml-[1rem] md:w-[40rem] lg:text-7xl lg:font-bold lg:text-indigo-900 lg:ml-20">
          How do you say Payroll in {currentLanguage}
        </h2>
        
        <p className="text-4xl font-normal text-indigo-900 -ml-[4rem] mt-5 md:text-4xl md:font-normal md:text-indigo-900 md:ml-[1.3rem] md:mt-5 lg:text-4xl lg:font-normal lg:text-indigo-900 lg:ml-20 lg:mt-5">
          {currentTranslation}
        </p>

        <div className="flex w-full md:flex md:w-full lg:flex lg:w-full 2xl:w-[100%]">
          <div className="w-full ml-20 md:w-full md:ml-20 lg:w-full lg:ml-20 2xl:w-[30rem]">
            <div className=" -ml-[9.25rem] bg-indigo-900 p-5 pr-0 rounded-full  mt-20 -mr-30  md:-ml-[4rem] md:bg-indigo-900 md:p-5 md:pr-0 md:rounded-full  md:mt-20 md:mr-[5rem]  lg:-ml-9 lg:bg-indigo-900 lg:p-5 lg:pr-0 lg:rounded-full  lg:mt-20 lg:-mr-30">
              <div className="chat chat-end  md:chat md:chat-end  lg:chat lg:chat-end ">
                <div className="chat-bubble text-white md:chat-bubble md:text-white lg:chat-bubble lg:text-white ">
                  What are our payroll costs this month?
                </div>
              </div>
            </div>
            <div className="bg-indigo-900 p-5 pr-2 -ml-[5.5rem] rounded-full shadow-md mt-10 -mr-[4.25rem] md:bg-indigo-900 md:p-5 md:pr-[0rem] md:ml-[6rem] md:rounded-full md:shadow-md md:mt-10 md:-mr-36 lg:bg-indigo-900 lg:p-5 lg:pr-0 lg:ml-10 lg:rounded-full lg:shadow-md lg:mt-10 xl:-mr-36 lg:mr-2 2xl:pr-[10rem] 2xl:-mr-[3rem]">
              <div className="chat chat-end  ">
                <div className="chat-bubble text-white">
                  Hi Steve!👋 Here are your current payroll runs:
                </div>
              </div>
            </div>
            <p className="md:hidden lg:block w-80 -ml-[7rem] mt-[35rem] text-2xl md:w-80 md:-ml-[0rem] md:mt-[7rem] md:text-2xl lg:w-80 lg:-ml-[0rem] lg:mt-[7rem] lg:text-2xl">Pay your global workforce on time - in their local currencies. Pay international contractors, your own employees, or international employees hired through our EOR entities. We’ll keep you and your team compliant with ever-changing taxes and regulations.</p>

          </div>

          <div className="h-[40rem] w-[15rem] m-5 mt-[9rem] -ml-[15rem] md:h-[40rem] md:w-[19rem] md:m-5 md:mt-[9rem] md:-ml-[10rem] lg:h-[40rem] lg:w-[13rem] 2xl:w-[7rem] lg:m-5 lg:-mt-[6rem] lg:ml-[10rem] 2xl:-ml-[5rem]">
            <div className="h-[29rem] w-[20rem] font-bold rounded-3xl ml-[2rem] mt-44 bg-white  shadow-xl md:h-[28rem] md:w-[35rem] md:font-bold md:rounded-32xl md:-ml-[14rem] md:mt-49 md:bg-white  md:shadow-xl lg:h-[28rem] lg:w-[12rem] lg:font-bold lg:rounded-32xl xl:ml-[3.75rem] lg:-ml-[8.2rem] lg:mt-44 xl:w-[21rem] lg:bg-white  lg:shadow-xl 2xl:ml-[26rem]">
            <p className="ml-4 -mt-2 pt-5  text-indigo-900 text-52xl md:ml-4 md:-mt-2 md:pt-5  md:text-indigo-900 md:text-52xl md:text-center lg:ml-4 lg:-mt-2 lg:pt-5 lg:text-left lg:text-indigo-900 lg:text-xl ">Payroll Made Simple </p>
            <div className="w-50 ml-2 md:w-50 md:ml-2 md:text-center  lg:w-50 lg:text-none lg:ml-2">
            <p className="text-gray-400 text-lg ml-2 mt-2 md:text-12xl md:ml-2 md:mt-[2rem] lg:text-sm lg:ml-2 lg:mt-2 ">Streamline payroll for your international employees in every country.
            <a href="#" className="text-indigo-900 hover:underline"> Read more</a></p>
            </div>
            
            <ul className="mt-10">
                <li className="m-2 text-normal text-lg text-indigo-900 bg-gray-100 rounded-32xl p-2 md:m-2 md:text-normal md:text-2xl md:text-indigo-900 md:bg-gray-100 md:rounded-32xl md:p-2 lg:m-2 lg:text-lg:normal lg:text-sm lg:text-indigo-900 lg:bg-gray-100 lg:rounded-32xl lg:p-2"> Submit incentives and expenses</li>
                <li className="m-2 text-normal text-lg text-indigo-900 bg-gray-100 rounded-32xl p-2 md:m-2 md:text-normal md:text-2xl md:text-indigo-900 md:bg-gray-100 md:rounded-32xl md:p-2 lg:m-2 lg:text-lg:normal lg:text-sm lg:text-indigo-900 lg:bg-gray-100 lg:rounded-32xl lg:p-2"> Approve and submit</li>
                <li className="m-2 text-normal text-lg text-indigo-900 bg-gray-100 rounded-32xl p-2 md:m-2 md:text-normal md:text-2xl md:text-indigo-900 md:bg-gray-100 md:rounded-32xl md:p-2 lg:m-2 lg:text-lg:normal lg:text-sm lg:text-indigo-900 lg:bg-gray-100 lg:rounded-32xl lg:p-2"> Remote processes payroll</li>
                <li className="m-2 text-normal text-lg text-indigo-900 bg-gray-100 rounded-32xl p-2 md:m-2 md:text-normal md:text-2xl md:text-indigo-900 md:bg-gray-100 md:rounded-32xl md:p-2 lg:m-2 lg:text-lg:normal lg:text-sm lg:text-indigo-900 lg:bg-gray-100 lg:rounded-32xl lg:p-2"> Employees get paid</li>
            </ul>
            </div>
            <div className="hidden  lg:block h-[18rem] w-[12rem] font-bold rounded-3xl ml-3 mt-14 bg-white xl:ml-[3.75rem] lg:-ml-[8.2rem] xl:w-[21rem] xl:h-[25rem] shadow-xl 2xl:ml-[26rem]">
            <p className=" -mt-2 pt-5  text-indigo-900 text-lg font-Bossa text-center">Payroll on track</p>
            <div className=" w-50 ml-2 ">
            <p className="text-gray-400 text-sm -ml-3 mt-2 text-center ">Nothing New</p>
            </div >
            <img className="mt-2 " src="https://images.ctfassets.net/dlbjkw4rng8s/5ZinmkfNGBdi5mGSkNPONU/02a8461930006152be7fb85eba82bcb3/Frame_220.svg" alt="no image" />
            
            </div>
          </div>
          
            {/* <img className= "h-[50rem] w-[0rem] -ml-[2.70rem] mt-30 md:w-[0rem] lg:w-[38.7rem] lg:ml-[1rem]  lg:h-[40.3rem] 2xl:w-[50.7rem] 2xl:h-[40rem] xl:ml-[10.3rem] 2xl:ml-[42.8rem] " src={IMG}/> */}
          
        </div>
      </div>
    </div>
  );
}

export default Payroll;
