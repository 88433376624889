import React from 'react';
import Navbar from './navbar';
import Footer from './footer';
 function hiring() {
    return (
      
        <div className="justify-between items-center ">
          <Navbar />
            
          <h2 className="text-5xl font-bold flex justify-center items-center h-screen  text-indigo-900">No vacancies are available now. 🤧</h2>
         <Footer />
         </div>
    );
 }
 
 export default hiring;