import React from 'react';
import Navbar from '../navbar';
import Footer from '../footer';
import { Link } from 'react-router-dom';

function FAQ() {
  const moveToDemo = () => {
    const urlToOpen = 'https://calendly.com/demofortokentalent/token-talent-demo';
    window.open(urlToOpen, '_blank');
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
     <Navbar />
     <div className='sm:py-16 sm:px-12  md:px-24 md:py-16 lg:py-32 lg:px-48 bg-[#eef0f4]'>
        <div className='item-center justify-center flex flex-col w-full text-center '>
          <h1 className='font-bold sm:text-6xl md:text-8xl text-center '>
          Frequently Asked Questions
          </h1>
          <p className='mt-10'>Get expert answers on the Frequently Asked Questions (FAQs) on Token Talent</p>
          <p className='font-bold mt-5'>Here are the answers to some common questions that our customers ask before they start using Token Talent:</p>
          
          
          
          <div className='space-y-4 mt-20'>

           

  {/* 1 */}

  <details className="group [&_summary::-webkit-details-marker]:hidden sm:mx-10 md:mx-20">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900 lg:-mt-[2.7rem]"
    >
      <h2 className="font-medium">
      Who is Token Talent best suited for?    </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <div className='my-5 p-5 rounded-lg bg-white'>
    <p className=" px-4 leading-relaxed text-gray-700 text-start">Token Talent is best suited for businesses of all sizes and industries that seek to optimize their HR management processes. Here's a breakdown of who can benefit the most from Token Talent:</p>
    <ul>
        <li><p className='text-start px-4 leading-relaxed text-gray-700 mt-5'><span className='font-bold'>Small and Medium-sized Enterprises (SMEs):</span>  SMEs often face resource constraints and require efficient HR solutions to manage their workforce effectively. Token Talent provides cost-effective and scalable HR management tools tailored to the needs of SMEs.</p></li>
        <li><p className='text-start px-4 leading-relaxed text-gray-700'><span className='font-bold'>Growing Startups:</span>  Rapidly expanding startups need agile HR solutions to support their growth. Token Talent provides scalable features that evolve alongside startups, enabling streamlined HR operations and enhanced focus on business expansion.</p></li>
        <li><p className='text-start px-4 leading-relaxed text-gray-700'><span className='font-bold'>Large Enterprises:</span>  Complex HR requirements are no challenge for Token Talent. Large enterprises can leverage its robust features and customization options to manage diverse teams across multiple locations, ensuring centralized control, compliance, and transparency.</p></li>
        <li><p className='text-start px-4 leading-relaxed text-gray-700'><span className='font-bold'>Multi-location Businesses:</span>  Token Talent's centralized platform is a boon for businesses with multiple branches or remote teams. It facilitates seamless communication, collaboration, and HR management across geographically dispersed locations.</p></li>
        <li><p className='text-start px-4 leading-relaxed text-gray-700'><span className='font-bold'>HR Professionals:</span>  Efficiency is key for HR professionals, and Token Talent's intuitive tools for attendance tracking, leave management, payroll processing, and policy enforcement are designed to enhance effectiveness in managing HR processes.</p></li>
        <li><p className='text-start px-4 leading-relaxed text-gray-700'><span className='font-bold'>Compliance-focused Industries:</span>  Token Talent is the go-to solution for industries with stringent regulatory requirements. From healthcare to finance and manufacturing, it ensures compliance with labor laws, industry regulations, and data privacy standards.</p></li>
    </ul>
</div>


  </details>

  {/* 2 */}

  <details className="group [&_summary::-webkit-details-marker]:hidden sm:mx-10 md:mx-20">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900"
      >
      <h2 className="font-medium">
      Why is Token Talent a cloud-based & blockchain based solution?     </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <div className='my-5 p-5 rounded-lg bg-white'>
    <p className=" px-4 leading-relaxed text-gray-700 text-start">Token Talent is a cloud-based and blockchain-based solution for several key reasons:</p>
    <ul>
        <li><p className='text-start px-4 leading-relaxed text-gray-700 mt-5'><span className='font-bold'>Scalability:</span>  Token Talent, being a cloud-based solution, offers scalability tailored to meet the evolving needs of businesses of all sizes. Whether your company is expanding its workforce or operating across multiple locations, Token Talent seamlessly scales its infrastructure to accommodate growth.</p></li>
        <li><p className='text-start px-4 leading-relaxed text-gray-700'><span className='font-bold'>Accessibility:</span> With Token Talent being cloud-based, it can be accessed from anywhere with an internet connection. This accessibility ensures that HR processes aren't constrained by physical locations, enabling employees and managers to conveniently and efficiently manage HR tasks.</p></li>
        <li><p className='text-start px-4 leading-relaxed text-gray-700'><span className='font-bold'>Cost-effectiveness:</span>  Cloud-based solutions like Token Talent eliminate the need for businesses to invest in on-premises infrastructure and maintenance. This cost-effective approach reduces upfront capital expenses, allowing businesses to pay only for the resources and services they utilize.</p></li>
        <li><p className='text-start px-4 leading-relaxed text-gray-700'><span className='font-bold'>Data Security:</span>  Leveraging blockchain technology, Token Talent ensures the highest level of data security and integrity. Blockchain's decentralized and immutable nature protects sensitive HR data from unauthorized access, tampering, or manipulation, thereby enhancing data security and privacy.</p></li>
        <li><p className='text-start px-4 leading-relaxed text-gray-700'><span className='font-bold'>Transparency and Trust:</span>  Blockchain technology fosters transparency and trust in HR processes by creating an auditable and tamper-proof record of transactions and data changes. This transparency builds trust among stakeholders, including employees, managers, and regulatory authorities.</p></li>
        <li><p className='text-start px-4 leading-relaxed text-gray-700'><span className='font-bold'>Compliance:</span> Cloud-based and blockchain-based solutions like Token Talent help businesses maintain compliance with industry regulations and data privacy laws. By securely storing and managing HR data on the blockchain, Token Talent ensures compliance with data protection regulations such as GDPR and HIPAA.</p></li>
    </ul>
</div>

    
  </details>

  {/* 3 */}

  <details className="group [&_summary::-webkit-details-marker]:hidden sm:mx-10 md:mx-20">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900"
      >
      <h2 className="font-medium">
      Can we access token talent from anywhere at any time?     </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <div className='my-5 p-5 rounded-lg bg-white'>
    <p className="px-4 leading-relaxed text-gray-700 text-start">Yes, Token Talent is accessible from anywhere and at any time. As a cloud-based solution, users can log in to Token Talent from any device with an internet connection. Whether in the office, at home, or on the go, users can conveniently access and manage HR tasks, such as attendance tracking, leave management, and payroll processing, with ease. This accessibility ensures seamless HR operations and enhances workforce productivity and efficiency.</p>
    
</div>

    
  </details>

  {/* 4 */}

  <details className="group [&_summary::-webkit-details-marker]:hidden sm:mx-10 md:mx-20">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900"
      >
      <h2 className="font-medium">
      Does Token Talent include a mobile application?     </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <div className='my-5 p-5 rounded-lg bg-white'>
    <p className="px-4 leading-relaxed text-gray-700 text-start">Yes, Token Talent includes a mobile application called the Token Talent Employee Self Service (ESS) app. Additionally, the Token Talent dashboard is responsive, allowing users to access and utilize its features conveniently via mobile browsing.</p>
    
</div>

    
  </details>

  {/* 5 */}

  <details className="group [&_summary::-webkit-details-marker]:hidden sm:mx-10 md:mx-20">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900"
      >
      <h2 className="font-medium">
      Can I try Token Talent before making a decision?     </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <div className='my-5 p-5 rounded-lg bg-white'>
    <p className="px-4 leading-relaxed text-gray-700 text-start">Yes, you can try Token Talent before making a decision. We offer a free trial period for you to explore the features and functionalities of our platform. <Link to='/signup' className='text-indigo-500 underline font-bold'>Sign up today</Link>  to experience how Token Talent can streamline your HR processes and empower your workforce.</p>
    
</div>

    
  </details>
{/* 6 */}

<details className="group [&_summary::-webkit-details-marker]:hidden sm:mx-10 md:mx-20">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900"
      >
      <h2 className="font-medium">
      How long does it take to implement Token Talent?     </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <div className='my-5 p-5 rounded-lg bg-white'>
    <p className="px-4 leading-relaxed text-gray-700 text-start">Token Talent boasts a rapid implementation process, typically taking only 5 minutes to set up and propagate attendance and leave data once employees log in with the Token Talent HR ESS app. This swift setup ensures minimal disruption to your operations and enables you to start managing HR tasks efficiently without delay.</p>
    
</div>

    
  </details>

  {/* 7 */}

  <details className="group [&_summary::-webkit-details-marker]:hidden sm:mx-10 md:mx-20">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900"
      >
      <h2 className="font-medium">
      Can Token Talent be integrated with other systems?     </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <div className='my-5 p-5 rounded-lg bg-white'>
    <p className="px-4 leading-relaxed text-gray-700 text-start">Yes, upon request and purchasing Token Talent Platinum, Token Talent offers open APIs that allow you to natively integrate with any other cloud solution. This integration capability enables seamless connectivity between Token Talent and your existing systems, enhancing data flow and streamlining your HR processes.</p>
    
</div>

    
  </details>

  {/* 8 */}

  <details className="group [&_summary::-webkit-details-marker]:hidden sm:mx-10 md:mx-20">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900"
      >
      <h2 className="font-medium">
      Can Token Talent be natively integrated with other hosted on-premise solutions?    </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <div className='my-5 p-5 rounded-lg bg-white'>
    <p className="px-4 leading-relaxed text-gray-700 text-start">No. Token Talent only natively integrates with other cloud solutions. In the case of locally hosted solutions, the only option is manual integration. This involves downloading an excel sheet from Token Talent with a specific format, which is then uploaded to the hosted solution to transfer the data.</p>
    
</div>

    
  </details>

  {/* 9 */}

  <details className="group [&_summary::-webkit-details-marker]:hidden sm:mx-10 md:mx-20">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900"
      >
      <h2 className="font-medium">
      What kind of customer support do you provide?   </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <div className='my-5 p-5 rounded-lg bg-white'>
    <p className="px-4 leading-relaxed text-gray-700 text-start">

We offer a comprehensive Service Level Agreement (SLA) for customer support. Our live support hours are from 8:00 AM to 6:00 PM Monday through Friday (GMT +5 Time Zone). Additionally, we provide one-on-one assistance with implementation and training. For timely assistance, all support tickets should be generated from Token Talent or sent to support@tokentalent.com. Moreover, for Token Talent Platinum Subscribers, we also provide a dedicated account manager who remains active according to your organization's time zone settings.</p>
    
</div>

    
  </details>

  {/* 10 */}
  <details className="group [&_summary::-webkit-details-marker]:hidden sm:mx-10 md:mx-20">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900"
      >
      <h2 className="font-medium">
      Do you have any security precautions and/or password authentication features?   </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <div className='my-5 p-5 rounded-lg bg-white'>
    <p className="px-4 leading-relaxed text-gray-700 text-start">Yes, Token Talent implements robust security measures and password authentication features to safeguard user data:</p>
    <ul>
        <li><p className='text-start px-4 leading-relaxed text-gray-700 mt-5'> Passwords are never stored in plain text; they are securely hashed and encrypted.</p></li>
        <li><p className='text-start px-4 leading-relaxed text-gray-700'>All data transmission between users and our servers is encrypted, ensuring confidentiality.</p></li>
        <li><p className='text-start px-4 leading-relaxed text-gray-700'>Our cloud servers are hosted in state-of-the-art data centers and adhere to stringent security policies.</p></li>
        <li><p className='text-start px-4 leading-relaxed text-gray-700'>Security protocols encompass digital and physical access controls to uphold data integrity and privacy.</p></li>
        
    </ul>
</div>

    
  </details>
  {/* 11 */}

  <details className="group [&_summary::-webkit-details-marker]:hidden sm:mx-10 md:mx-20">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900"
      >
      <h2 className="font-medium">
      How much will Token Talent cost me?  </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <div className='my-5 p-5 rounded-lg bg-white'>
    <p className="px-4 leading-relaxed text-gray-700 text-start">The cost of Token Talent depends on several factors, including the number of users, the selected plan (Silver, Gold, Platinum), and any additional customization or features required. To receive a personalized pricing quote tailored to your specific needs, please contact our sales team or visit our website to explore our pricing options and features in detail. <Link to='/pricing'><span onClick={scrollToTop} className='font-bold text-indigo-500 underline'>See Pricing</span></Link>  </p>
    
</div>

    
  </details>

  {/* 12 */}
  <details className="group [&_summary::-webkit-details-marker]:hidden sm:mx-10 md:mx-20">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900"
      >
      <h2 className="font-medium">
      Are there any hidden costs?  </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <div className='my-5 p-5 rounded-lg bg-white'>
    <p className="px-4 leading-relaxed text-gray-700 text-start">At Token Talent, we believe in transparency and strive to ensure there are<span className='text-indigo-500 font-bold'> no hidden costs</span>  associated with our services. Our pricing structure is designed to be clear and straightforward, covering the features and services outlined in your chosen plan.</p>
    
</div>

    
  </details>

  {/* 13 */}

  <details className="group [&_summary::-webkit-details-marker]:hidden sm:mx-10 md:mx-20">
    <summary
      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white p-4 text-gray-900"
      >
      <h2 className="font-medium">
      How can I book a live guided demo on Token Talent?  </h2>

      <svg
        className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </summary>

    <div className='my-5 p-5 rounded-lg bg-white'>
    <p className="px-4 leading-relaxed text-gray-700 text-start">Booking a live guided demo on Token Talent is quick and easy. Simply <span className='font-bold text-indigo-500 underline cursor-pointer ' onClick={moveToDemo}>Click here</span></p>
    
</div>

    
  </details>

  
          </div>

        </div>
    </div>
     <Footer />
    </>
  )
}

export default FAQ
