import CryptoJS from 'crypto-js';

export function encriptmydata(data, Keys) {
  const sensitiveData = JSON.stringify(data);

  if (sensitiveData.length === 0 || Keys.length === 0) {
    return "Error: Empty input data or secret key";
  }

  const encryptedData = CryptoJS.AES.encrypt(sensitiveData, Keys).toString();
  return encryptedData;
}

export function decryptMydata(data, Keys) {
  const decryptedData = CryptoJS.AES.decrypt(data, Keys).toString(CryptoJS.enc.Utf8);
  JSON.stringify(decryptedData).toString();
  return decryptedData;
  
}

export function EncryptionAndDecryptionKeys() {
  const Keys = "@Token!234)DeltaPure";
  return Keys;
  
}
export function SingupAPi() {
  const Keys = "https://humshroqg7.execute-api.us-east-1.amazonaws.com/Dev/organization";
  return Keys;
  
}