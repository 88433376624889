import { v4 as uuidv4 } from 'uuid';// Import the UUID library

export function GenerateCompanyID(companyName, companyEmail, phoneNumber) {
  const cleanedCompanyName = companyName.replace(/[^a-zA-Z0-9]/g, '').slice(0, 2);
  const cleanedEmail = companyEmail.replace(/[^a-zA-Z0-9]/g, '').slice(0, 3);
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '').slice(0, 3);
  const uniqueID = uuidv4().slice(0, 4); 
//   const companyID = `${cleanedCompanyName}${cleanedEmail}${cleanedPhoneNumber}${uniqueID}`;
const companyID = `${uniqueID}${cleanedCompanyName}${cleanedPhoneNumber}${cleanedEmail}${uniqueID}`;
  if (companyID.length > 10) {
    return companyID.slice(0, 10);
  } else if (companyID.length < 10) {
    return companyID.padEnd(10, '0');
  } else {
    return companyID;
  }
}