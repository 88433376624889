import Pricing from "./components/pricing";
import Home from "./Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import  SignUp  from "./components/signup";
import Termservices from "./components/termservices"
import PrivacyPolicy from "./components/privacypolicy"
import Hiring from "./components/hiring"
import Features from "./components/features";
import Blogs from "./components/rescources/Blogs";
import FAQ from "./components/rescources/FAQ";
import MYAbout from "./components/rescources/About";

function App() {  
  const blogs = [
    {
      title: 'Lorem Ipsum',
      excerpt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      url: 'https://example.com/blog/lorem-ipsum',
    },
    {
      title: 'Dolor Sit Amet',
      excerpt: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      url: 'https://example.com/blog/dolor-sit-amet',
    },
    {
      title: 'Lorem Ipsum',
      excerpt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      url: 'https://example.com/blog/lorem-ipsum',
    },
    {
      title: 'Dolor Sit Amet',
      excerpt: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      url: 'https://example.com/blog/dolor-sit-amet',
    },
    {
      title: 'Lorem Ipsum',
      excerpt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      url: 'https://example.com/blog/lorem-ipsum',
    },
    {
      title: 'Dolor Sit Amet',
      excerpt: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      url: 'https://example.com/blog/dolor-sit-amet',
    },
    // Add more blog objects as needed
  ];
  
  return (
    
    <div className=" ">
      <Routes>
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/*" element={<Home />} />   
        <Route path="/termsandCondition" element={<Termservices/>} />
        <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
        <Route path="/hiring" element={<Hiring/>} />
        <Route path="/features" element={<Features/>} />
        <Route path="/blogs" element={<Blogs blogs={blogs}/>} />
        <Route path="/faqs" element={<FAQ/>} />
        <Route path="/about" element={<MYAbout/>} />
        <Route path="/signup/*" element={<SignUp/>} />
 
        

      </Routes>
      
    </div>
  );
}

export default App;