import React from 'react'
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Link } from 'react-router-dom';


function Reason() {
    const moveToDemo = () => {
        const urlToOpen = 'https://calendly.com/demofortokentalent/token-talent-demo';
        window.open(urlToOpen, '_blank');
      };
  return (
    <div className='bg-indigo-900 sm:m-5 md:m-16 rounded-xl sm:p-10 md:p-20 shadow-lg shadow-indigo-400'>
        <div className='flex flex-col text-center items-center justify-center  '>
        <h1 className='text-white rounded-lg font-bold sm:text-4xl md:text-5xl bg-indigo-500 p-5'>
        TECHNOLOGY WITH THE POWER TO TRANSFORM HR
        </h1>
        
        <h3 className='text-white font-bold text-2xl mt-5'>
        Tackling your HR needs from Acquire to Retire.
        </h3>
        <div className='flex flex-row gap-5 m-10'>
        <Link to="/signup">
        <button className='bg-white  font-bold text-indigo-500 px-5 py-3 shadow-md rounded-full hover:text-white hover:bg-indigo-500'>Get started</button>

        </Link>
            
            <button className='text-white font-medium px-5 py-3 border border-solid border-indigo-900 rounded-full hover:border-white' onClick={moveToDemo}>Let's Talk</button>

        </div>
        <div className='mt-5 flex-col text-start'>

            <div className='flex items-center gap-1'>
            <IoIosCheckmarkCircle className='text-green-500 h-8 w-8' />
            <p className='text-white text-md'>Hassle-free sign-up process</p>
            </div>
            <div className='flex items-center gap-1'>
            <IoIosCheckmarkCircle className='text-green-500 h-8 w-8' />
            <p className='text-white text-md'>Amazing after-sales support</p>
            </div>
            <div className='flex items-center gap-1'>
            <IoIosCheckmarkCircle className='text-green-500 h-8 w-8' />
            <p className='text-white text-md'>Fully localized HR system</p>
            </div>
            
            
            

        </div>
        {/* <div className='flex'>
             
            <p className='text-white text-md'>Amazing after-sales support</p>
            

        </div>
        <div className='flex'>
            
            <p className='text-white text-md'>Fully localized HR system</p>
            

        </div> */}
        <div className='mt-28'>

        </div>

        <div className='md:mx-28 flex gap-5 flex-col'>
        <h1 className='text-white font-bold text-2xl'>
            Reasons to switch in a HR software like Token Talent:
            </h1>
            <div className='md:mx-22  flex sm:flex-col md:flex-row gap-5 items-center'>


<div class="p-4  shadow-md rounded-2xl bg-gray-800 hover:bg-gray-900  hover:shadow-lg shadow-indigo-500">
    <div class="flex items-center">
        <span class="relative p-4 bg-indigo-500 rounded-xl">
            <svg width="40" fill="currentColor" height="40" class="absolute h-4 text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                </path>
            </svg>
        </span>
        <p class="ml-2 text-black text-md dark:text-white">
        Engagement
        </p>
    </div>
    <div class="flex flex-col justify-start">
    <div class="flex items-center text-sm text-green-500">
        <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1408 1216q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z">
                </path>
            </svg>
            <p class="my-4 text-4xl font-bold text-left text-gray-700 dark:text-gray-100">
            
            67
            <span class="text-sm">
                %
            </span>
        </p>
        </div>
        <div class="flex items-center text-sm text-green-500">
            <span class="text-gray-400">
            Increase in employee engagement 
            </span>
        </div>
    </div>
</div>

<div class="p-4  shadow-md rounded-2xl bg-gray-800 hover:bg-gray-900  hover:shadow-lg shadow-indigo-500">
    <div class="flex items-center">
        <span class="relative p-4 bg-indigo-500 rounded-xl">
            <svg width="40" fill="currentColor" height="40" class="absolute h-4 text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                </path>
            </svg>
        </span>
        <p class="ml-2 text-black text-md dark:text-white">
        Time & Cost
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <div class="flex items-center text-sm text-green-500">
        <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1408 1216q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z">
                </path>
            </svg>
            <p class="my-4 text-4xl font-bold text-left text-gray-700 dark:text-gray-100">
            
            88
            <span class="text-sm">
                %
            </span>
        </p>
        </div>
        
       
        <div class="flex items-center text-sm text-green-500">
            <span class="text-gray-400">
            Increase in time & budget savings
            </span>
        </div>
    </div>
</div>

<div class="p-4  shadow-md rounded-2xl bg-gray-800 hover:bg-gray-900  hover:shadow-lg shadow-indigo-500">
    <div class="flex items-center">
        <span class="relative p-4 bg-indigo-500 rounded-xl">
            <svg width="40" fill="currentColor" height="40" class="absolute h-4 text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                </path>
            </svg>
        </span>
        <p class="ml-2 text-black text-md dark:text-white">
        Security
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <div class="flex items-center text-sm text-green-500">
        <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1408 1216q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z">
                </path>
            </svg>
            <p class="my-4 text-4xl font-bold text-left text-gray-700 dark:text-gray-100">
            
            78
            <span class="text-sm">
                %
            </span>
        </p>
        </div>
        
       
        <div class="flex items-center text-sm text-green-500">
            <span class="text-gray-400">
            Increase in security and compliance
            </span>
        </div>
    </div>
</div>




            </div>
            

        </div>

        

        {/* <div className=' md:mx-28  flex sm:flex-col md:flex-row gap-5 '>
            <h1 className='text-white font-bold text-2xl'>
            Reasons to invest in a HR software like Token Talent:
            </h1>
            <div className='flex flex-col sm:items-center md:items-start justify-center px-5 sm:mt-5 md:mt-0'>
                <div className='flex flex-row'>
                <p className='text-white font-bold text-5xl'>53</p>
            <p className='text-white'>%</p>
                </div>
                <p className='text-white sm:text-center md:text-start'>
                Increase in employee engagement 
                </p>

            </div>

            <div className='flex flex-col sm:items-center md:items-start justify-center px-5 sm:mt-5 md:mt-0'>
                <div className='flex flex-row'>
                <p className='text-white font-bold text-5xl'>60</p>
            <p className='text-white'>%</p>
                </div>
                <p className='text-white sm:text-center md:text-start'>
                Increase in time savings
                </p>

            </div>

            <div className='flex flex-col sm:items-center md:items-start justify-center px-5 sm:mt-5 md:mt-0'>
                <div className='flex flex-row'>
                <p className='text-white font-bold text-5xl'>78</p>
            <p className='text-white'>%</p>
                </div>
                <p className='text-white sm:text-center md:text-start'>
                Increase in security and compliance
                </p>

            </div>
            
        </div> */}


        </div>
       
        
      
    </div>
  )
}

export default Reason
