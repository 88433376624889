import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BounceLoader from "react-spinners/BounceLoader";
import { fetchPaymentDetails } from '../../util/VerifyPayments';
import { EncryptionAndDecryptionKeys, decryptMydata } from '../../util/encriptPass';
import { SignupData } from '../restapi';
import { Country, State, City }  from 'country-state-city';

function Profile() {
  const mycountryArray = Country.getAllCountries();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [mystatesArray, setStatesArray] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [mycitiesArray, setCitiesArray] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState([]);
  const [myselectedCurrency, setmySelectedCurrency] = useState(null);

  useEffect(() => {
    if (selectedCountry && selectedState) {
      const fetchedCitiesArray = City.getCitiesOfState(selectedCountry.isoCode, selectedState.isoCode);
      setCitiesArray(fetchedCitiesArray);
    } else {
      setCitiesArray([]);
    }
    setSelectedCity(null);
  }, [selectedCountry, selectedState]);


  const checkDisability = () => {
    return !selectedCountry || !selectedState || !selectedCity || !selectedTimezone || !myselectedCurrency;
  };




  const handleCountrySelect = (isoCode) => {
    const foundCountry = mycountryArray.find(country => country.isoCode === isoCode);
    setSelectedCountry(foundCountry);
  
    const fetchedStatesArray = State.getStatesOfCountry(isoCode);
    setStatesArray(fetchedStatesArray);

    setSelectedState(null);
    setSelectedCity(null);
    setSelectedTimezone(null);
    setmySelectedCurrency(null);
  };
  

  const handleStateSelect = (isoCode) => {
    const selectedState = mystatesArray.find(state => state.isoCode === isoCode);
    setSelectedState(selectedState);
  };

  const handleCitySelect = (isoCode) => {
    const FoundingCity = isoCode;
    if (FoundingCity) {
      setSelectedCity(FoundingCity);
    } else {
      setSelectedCity(null);
    }
    // console.log(FoundingCity)
    // setSelectedCity(FoundingCity);
  };

  const handleTimezoneSelect = (timezone) => {
    setSelectedTimezone(timezone);
  };
  const handleCurrencySelect = (isoCode) => {
    const foundingCounrty = mycountryArray.find(country => country.isoCode === isoCode);
    setmySelectedCurrency(foundingCounrty.currency);
  };

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#1A237E");
  const [error, myErorr]= useState(null);

  const key = EncryptionAndDecryptionKeys();
  const email = localStorage.getItem('em'); 
  const pass = localStorage.getItem('pa');
  const name = localStorage.getItem('na');
  const id = localStorage.getItem('i');
  const decryptedemail = JSON.parse(decryptMydata(email, key))
  const decryptedPasswords = JSON.parse(decryptMydata(pass, key))
  const decryptedname = JSON.parse(decryptMydata(name, key))
  const decryptedID = JSON.parse(decryptMydata(id, key))


  const verifyPayement = async ()=>{
    setLoading(true);
    const status = await fetchPaymentDetails({email: decryptedemail});
    // console.log(status)
    if (status === true) {
      setLoading(false);
    }else{
      setLoading(false);
      myErorr(true);
    }
  }



  useEffect(() => {
    verifyPayement();
  }, []);

    const decriptExistingData = () =>{

      console.log('city name is :'+selectedCity)
      
      navigate("/signup/address",{state: {name:decryptedname, email:decryptedemail, password:decryptedPasswords, currency: myselectedCurrency,
         country: selectedCountry.name, timezone: selectedTimezone, state: selectedState.name, city: selectedCity, id: decryptedID} });
    }



    const OnFormclik = (e) =>{
        e.preventDefault();
        decriptExistingData();
        
    
      }
      const steps = [
        { index: 1, description: "Company Info" },
        { index: 2, description: "OTP Verification" },
        { index: 3, description: "Subscription Selection" },
        { index: 4, description: "Location Details" },
        { index: 5, description: "Additional Info" }
      ];
 
  
  
    return (
      <div>
        <div className=" flex min-h-full flex-1 flex-col justify-center px-6 pt-[3rem] p-[2rem] -ml-[36rem] md:p-0 md:ml-0 lg:ml-0 md:py-12 lg:px-8">
        <div className="hidden md:flex items-center justify-center mb-7">
        {steps.map((step, index) => (
          <div key={step.index} className="relative">
            <div className={`h-10 w-10 flex items-center justify-center rounded-full border-2 mx-2 border-indigo-900 ${
              step.index === 4 ? 'bg-indigo-900 text-white' : 'text-indigo-900'
            }`}>
              {step.index}
            </div>
            <p className={`text-indigo-900 mt-2 text-sm mx-2  ${step.index === 4 && 'font-bold'}`}>{step.description}</p>
            {index !== steps.length - 1 && (
              <div className="absolute top-5 left-14 h-px w-14 bg-indigo-900 mx-3"></div>
            )}
          </div>
        ))}
      </div>
          <div className="mt-[rem] md:mt- sm:mx-auto sm:w-full sm:max-w-sm">
       
      {
        loading === true ? (<div className='flex text-center justify-center content-center h-1/2'>
          <BounceLoader
          color={color}
          loading={loading}
          size={60}
          aria-label="Loading Spinner"
          data-testid="loader"  
        />
        {/* <p>loading</p> */}
        </div>):(
          
        <div>
          {
            myErorr === true ? (
            <div className='flex text-center justify-center content-center h-1/2'>
              <p className='text-red-500'>{error}</p>

            </div>
            ) : (
            <div>
              <form onSubmit={ OnFormclik }>
            <div>
    <div className="mt-2">
      <div className="">
        <div className="relative h-10 w-72 lg:w-[24rem] ">
        <select
            required
            onChange={(e) => handleCountrySelect(e.target.value)}
            className="block h-10 ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border border-gray-300 py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-indigo-900 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
          >
            <option value="">Select a country</option>
            {mycountryArray.map(country => (
          <option key={country.isoCode} value={country.isoCode}>
            {country.name}
          </option>
        ))}
           
          </select>
  
        </div>
      </div>
    </div>
  </div>
  {
    mystatesArray.length > 0 && (
      <div>
            
                <div className="mt-2">
                <div className="relative h-10 w-72 lg:w-[24rem] ">
                  

                <select onChange={(e) => handleStateSelect(e.target.value)}
                required
                 className="block h-10 ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border border-gray-300 py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-indigo-900 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                >
          <option
          
          value="">Select a state</option>
            {mystatesArray.map(state => (
              <option key={state.isoCode} value={state.isoCode}>
                {state.name}
              </option>
            ))}
          </select>
                  </div>
                </div>
              </div>

    )
  }
  
              
  
              <div>

                {
                  mycitiesArray.length > 0 && (
                    <div>
                <div className="mt-2">
                <div className="relative h-10 w-72 lg:w-[24rem] ">
                  

                <select onChange={(e) => handleCitySelect(e.target.value)}
                required
                 className="block h-10 ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border border-gray-300 py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-indigo-900 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                >
          <option
          
          value="">Select a City</option>
           {mycitiesArray.map(city => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>
                  </div>
                </div>
             
                </div>

                  )
                }
                {
                  selectedCountry && selectedCountry.timezones && selectedState && (
                    <div>

                  <div className="mt-2">
                  <div className="relative h-10 w-72 lg:w-[24rem] ">
                  

                  <select onChange={(e) => handleCurrencySelect(e.target.value)}
                  required
                   className="block h-10 ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border border-gray-300 py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-indigo-900 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                  >
            <option
            
            value="">Select a currency</option>
              <option value={selectedCountry.isoCode}>{selectedCountry.currency}</option>
            </select>
                    </div>


      </div>
                </div>

                  )
                }
  
                
                {
                  selectedCountry && selectedCountry.timezones && selectedState && (
                    <div>

                  <div className="mt-2">
                  <div className="relative h-10 w-72 lg:w-[24rem] ">
                  

                  <select onChange={(e) => handleTimezoneSelect(e.target.value)}
                  required
                   className="block h-10 ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border border-gray-300 py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-indigo-900 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                  >
            <option
            
            value="">Select a timezone</option>
              {selectedCountry.timezones.map(timezone => (
              <option key={timezone.zoneName} value={timezone.gmtOffsetName}>
                {`${timezone.tzName} (${timezone.gmtOffsetName})`}
              </option>
            ))}
            </select>
                    </div>


      </div>
                </div>

                  )
                }
                
                
                <div className="hidden md:block"></div>
              </div>
              <div className="flex flex-col items-center justify-center  w-[19rem] md:w-full ml-[20rem] md:ml-0 mt-10 text-center">
            <button
                  disabled={!checkDisability}
                  className="mt-2  w-[19rem] md:w-[11.5rem] flex py-3 px-[3rem] md:px-[0rem] md:py-3 justify-center rounded-md bg-indigo-900 text-sm md:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:none md:flex-none md:-mt-[0rem] md:ml-0 lg:ml-2 disabled:bg-indigo-300"
                  // onClick={handleNext}
                  type="submit"
                >
                  Continue
                </button>
                </div>
            </form>

            </div>)
          }
          
        </div>)
      }
          
          </div>
        </div>
      </div>
    );
}

export default Profile