import React from 'react';
import { Link } from 'react-router-dom';
import myImg from '../images/team.png'

function management() {
  return ( <div className="bg-indigo-900 sm:m-5 md:m-16 rounded-2xl shadow-lg shadow-indigo-400">
  <div className="px-5 md:px-10">
    <div className="mx-auto w-full max-w-8xl">  
      <div className="py-16 md:py-24 lg:py-32">
        <div className="">
          <div className="">
            <div className="">
              <div className="grid grid-cols-2 max-[991px]:flex max-[991px]:flex-col max-[991px]:gap-y-16 gap-x-32 items-center justify-center">
              <div className="flex  items-center justify-center sm:w-full">
                  <img alt="A graphic of a world map with connecting lines and portraits, symbolizing international team communication or HR relations." src={myImg} className="w-full object-cover " />
                </div>
                <div className="w-full p-4  sm:w-full sm:p-6 md:p-10 md:mt-[13rem] lg:mt-0 lg:-ml-[4rem] bg-indigo-500 rounded-lg">
                  <div className="mb-4">
                    <h2 className="text-white text-4xl font-bold md:text-7xl">Manage your global team in one place</h2>
                  </div>
                  <div className="mb-12">
                    <div className="flex flex-row items-center gap-x-4 max-[479px]:flex-row max-[479px]:justify-start max-[479px]:gap-y-[0px] sm:items-center">
                      <p className="text-xl text-white">Whether you work with 1 team member or 1,000, you can bring the whole team together, and simplify your HR stack with Token Talent</p>
                    </div>
                  </div>
                  <Link to="/features" className="inline-block cursor-pointer border border-solid  bg-white px-6 py-4 text-center text-indigo-900 font-bold  transition hover:border-black hover:bg-indigo-900 hover:text-white rounded-full mr-5 md:mr-6 lg:mr-8">See Differences</Link>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> );
}

export default management;