import React from 'react';
import { Link, Route, Routes } from "react-router-dom";
import CostCalculatorImage from "../images/cost_calculator.png";
import Logo from "../images/logo512.png";
import ManageTeamImage from "../images/manage_team.png";
import Address from './SignUpSteps/Address';
import Profile from './SignUpSteps/Profile';
import StepOne from './SignUpSteps/StepOne';
import StepTwo from './SignUpSteps/StepTwo';
import Subcription from './SignUpSteps/Subcription';
import Success from './SignUpSteps/Success';

function Signup() {
  return (
    <div>
        <div className="flex h-screen w-screen">
        <div className="bg-indigo-900 w-[16rem] xl:w-[24rem]  relative hidden lg:block overflow-hidden">
          <Link
            to="/"
            className="flex items-center ml-[0.5rem] text-white p-10 "
          >
            <img src={Logo} className="h-8 mr-3" alt="Flowbite Logo" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-indigo-900">
              Token Talent
            </span>
          </Link>
          <div className="">
            <p className="text-white p-[3rem]  text-2xl font-bold">
              Sign up and come on in.
            </p>
            <p className="text-white p-[3rem] -mt-[5rem]">
              Sign up is simple, free and fast. One place to manage everything,
              and everyone.
            </p>
          </div>
          <div className="absolute -top-10 left-12 mt-[30rem] ">
            <div className="absolute -top-[5.5rem] left-12 mt-[30rem]">
              <img
                src={CostCalculatorImage}
                alt="Cost Calculator"
                style={{ width: "50%", height: "40%" }}
              />
            </div>
            <div className="absolute top-6 left-20 ml-[7rem] mt-[18rem] rounded-full">
              <img
                src={ManageTeamImage}
                alt="Manage Team"
                style={{
                  width: "70%",
                  height: "20%",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                }}
              />
            </div>
          </div>
        </div>

        <div className=" mx-auto items-center justify-center   overflow-y-auto w-full ">
          <div>
            <div className="  mx-auto w-screen max-w-sm md:mt-[5rem]">
            <Link
              to="/"
              className="flex items-center  text-indigo-900 px-4 ml-[9rem] mt-[4rem] md:mt-0 md:ml-[10rem] lg:ml-[9rem] py-2 rounded-full"
            >
              <img
                src={Logo}
                className="h-[3rem] md:h-10 mr-3"
                alt="Flowbite Logo"
              />
            </Link>{" "}
            <h1 className="hidden md:block w-[20rem] -mt-[10rem] md:mt-1 md:ml-[3rem] text-center text-4xl md:text-2xl font-bold  tracking-tight text-indigo-900">
              Welcome to Token Talent
            </h1>
            <h2 className='text-center mt-3 text-black font-bold'>Sign up your company</h2>
            <p className='text-center text-xs mt-1 text-gray-900'>All we need is a few company details. Then you'll be set up and able to simplify your global HR using Token Talent</p>
            

            
            </div>
            <div className="step-content mx-auto max-w-8xl">
            <Routes>
              <Route path='/' element = {<StepOne/>} />
              <Route path='/otp' element = {<StepTwo/>} />
              <Route path='/subscription' element = {<Subcription/>} />
              <Route path='/profile' element = {<Profile/>} />
              <Route path='/address' element = {<Address/>} />
              <Route path='/verified' element = {<Success/>} />
            </Routes>
          </div>
         
          </div>
          
          
        </div>
      </div>
      
    </div>
  )
}

export default Signup
