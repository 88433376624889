import React, { useRef, useState } from 'react';
import Macbook from '../images/macbook.png';
import emailjs from '@emailjs/browser';

function Contact() {
  const form = useRef();
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [statusColor, setStatusColor] = useState('');
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.sendForm('service_to65qde', 'template_edbxhvi', form.current, 'Hrb1KSls9B9axmbXa')
      .then((result) => {
        setLoading(false);
        setStatusColor('green');
        setStatus('Message sent successfully');
        setFormData({
          user_name: '',
          user_email: '',
          message: ''
        });
          
      }, (error) => {
        setLoading(false);
        setStatusColor('red');
        setStatus('Something went wrong while sending the message');
      });
  };

  return (
    <section className="bg-blue-200 block">
      <div className="px-5 md:px-10">
      <div className="mx-auto w-full max-w-7xl">
        <div className="py-16 md:py-24 lg:py-32">
          <div className="grid items-center justify-center text-center max-w-[991px] justify-items-start grid-cols-1 lg:grid-cols-2 gap-8 sm:gap-20 lg:ml-[8rem] 0">
            <div className="">
              <h2 className="font-bold mb-9 text-3xl md:text-5xl sm:text-center">Let's build something exciting together!</h2>
              {/* <div className="ml-0 mr-0 mt-4 max-w-[528px] mb-5 md:mb-6 lg:mb-8 pb-4">
              </div> */}
              {/* <div className="mb-4 flex items-center max-w-[991px] ">
                <img src="https://assets.website-files.com/6344564e41b7185978bcb175/6344ae2b569e53cbca3db723_star.svg" alt="" className="inline-block max-w-full mr-1 w-5 pl-0" />
                <img src="https://assets.website-files.com/6344564e41b7185978bcb175/6344ae2b569e53cbca3db723_star.svg" alt="" className="inline-block max-w-full mr-1 w-5 pl-0" />
                <img src="https://assets.website-files.com/6344564e41b7185978bcb175/6344ae2b569e53cbca3db723_star.svg" alt="" className="inline-block max-w-full mr-1 w-5 pl-0" />
                <img src="https://assets.website-files.com/6344564e41b7185978bcb175/6344ae2b569e53cbca3db723_star.svg" alt="" className="inline-block max-w-full mr-1 w-5 pl-0" />
                <img src="https://assets.website-files.com/6344564e41b7185978bcb175/6344ae2b569e53cbca3db723_star.svg" alt="" className="inline-block max-w-full mr-1 w-5 pl-0" />
              </div> */}
              <div className="mb-8  ">
                <p className="text-[#636262] text-sm w-full text-center">Please do not hesitate to get in touch with us if you have any suggestions or feedback. We value your input greatly..</p>
              </div>
              <div className="flex-row flex items-center justify-center ">
                <img src={Macbook} alt="" className="inline-block h-16 w-16 max-w-full object-cover mr-4 rounded-full" />
                <div className="flex-col flex items-start">
                  <h6 className="text-base font-bold">Umar Saeed</h6>
                  <p className="text-[#636262] text-sm sm:text-sm">Support Specialist</p>
                </div>
              </div>
            </div>
            <div className=" w-full bg-[#f2f2f7] px-8 rounded-xl ml-0 mr-0 pt-[2em] pb-8 ">
              <div className="text-center">
                <h3 className="font-bold text-2xl md:text-3xl">Contact Us</h3>
                <div className="mx-auto mt-4 max-w-[480px] mb-5 md:mb-6 lg:mb-8">
                  <div className="text-[#636262] text-sm sm:text-sm">Reach out to us for inquiries, support, or any assistance you require. We're available to assist you promptly and effectively.</div>
                </div>
                <div className="mx-auto w-full max-w-[400px]">
                  <div className="mx-auto max-w-[400px] text-left mb-4">
                    <form ref={form} onSubmit={sendEmail} >
                      <div className="relative mb-2">
                        <input value={formData.user_name} onChange={handleInputChange} type="text" name="user_name"  className="m-0 mb-4 block w-full  border-solid border-0 align-middle text-[#333333] focus:border-[#3898ec] text-sm px-3 rounded-md h-9 py-6 pl-4" maxLength="256"  placeholder="Name" required />
                      </div>
                      <div className="relative mb-2">
                        <input type="email"  value={formData.user_email} onChange={handleInputChange} className="m-0 mb-4 block w-full border-0 border-solid  align-middle text-[#333333] focus:border-[#3898ec] text-sm px-3 rounded-md h-9 py-6 pl-4" maxLength="256" name="user_email" placeholder="Email Address" required />
                      </div>
                      <div className="relative mb-5 md:mb-6 lg:mb-8">
                        <textarea placeholder="Project Brief" maxLength="5000" value={formData.message} onChange={handleInputChange} name="message" className="m-0 block h-auto min-h-[128px] w-full overflow-auto border border-solid  bg-white align-middle text-[#333333] focus:border-[#3898ec] text-sm mb-2.5 px-3 py-2 rounded-md pl-4" required />
                      </div>
                      <div className='button-container flex flex-col items-center justify-center  md:flex-row md:mt-[1rem] lg:mt-[2rem] mb-[2rem]'>
                      <button
              type="submit"
              value='Send'
                className={`transition-all duration-400 ease-in-out ml-3  ${
                  loading
                    ? 'w-14 h-14 bg-indigo-900 text-white rounded-full'
                    : 'm-0 inline-block w-full cursor-pointer items-center bg-indigo-900 hover:bg-indigo-500 px-6 py-3 text-center font-semibold text-white rounded-full'
                } hover:bg-indigo-700`}
                // onClick={MoveTODashboard}
                disabled={loading}
              >
                {loading ? (
                  <div className=" animate-spin flex items-center justify-center ">
                    <svg
                      className="w-6 h-6 text-white "
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                     
                  <circle
                    className='opacity-25 '
                    cx='12'
                    cy='12'
                    r='10'
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>

                    </svg>
                  </div>
                ) : (
                  'Send'
                )}
              </button>
              </div>
                    </form>
                    <p style={{ color: statusColor }} className='mt-4 text-center'>{status}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
}

export default Contact;
