import React from "react";
import Dashboard from '../images/landing_main.png';
import { TypeAnimation } from 'react-type-animation';
import { Link } from 'react-router-dom';

const moveToDemo = () => {
  const urlToOpen = 'https://calendly.com/demofortokentalent/token-talent-demo?month=2023-10';
  window.open(urlToOpen, '_blank');
};

function Landing() {
  return (
    <div className="lg:-mt-[4rem]">
      <div className="h-500 pb-20 pt-10 w-screen md:w-screen justify-between bg-indigo-900 md:h-500 md:bg-indigo-900 lg:flex lg:h-500 lg:bg-indigo-900 lg:w-screen 2xl:w-screen">
        <div className="md:mt-10 p-8 lg:w-1/2 lg:p-8 2xl:p-[5rem] flex flex-col items-center justify-center">
          <div className="sm:h-64 md:h-52 lg:h-72 xl:h-56">
            <h1 className="w-[23rem] text-6xl text-center font-bold text-white md:w-[40rem] lg:w-[30rem] xl:w-[40rem] md:text-7xl md:text-center md:font-bold md:text-white lg:text-7xl lg:font-bold lg:text-white 2xl:text-7xl 2xl:w-[38rem]">
              The HR platform
              <TypeAnimation
                className="text-6xl text-white font-bold text-center lg:text-7xl lg:font-bold lg:text-white 2xl:text-7xl"
                sequence={[
                  'for global teams',
                  2500,
                  'for global payroll',
                  2500,
                  'for global employment',
                  2500
                ]}
                speed={50}
                style={{ display: 'inline-block' }}
                repeat={Infinity}
              />
            </h1>
          </div>
          <p className="text-center mt-9 w-[23rem] -ml-3 text-white text-2xl md:mt-9 md:text-gray-300 md:ml-[2rem] md:w-[40rem] lg:w-[30rem] xl:w-[40rem] md:text-lg lg:mt-9 lg:text-gray-300 lg:text-lg 2xl:text-xl 2xl:w-[38rem]">
            Token Talent makes running global teams simple. HRIS, payroll, international
            employment, and contractor management, and more – all in one Global HR
            Platform.
          </p>
          <div className="flex sm:flex-col md:flex-row items-center justify-center">
            <Link to="/signup">
              <button className="bg-white p-3 pl-9 pr-9 rounded-full mt-9 hover:shadow-sm text-indigo-900 font-bold md:bg-white transition hover:border-black hover:bg-indigo-500 hover:text-white md:ml-[9rem] md:w-[12rem] md:pl-9 md:pr-9 md:rounded-full md:mt-9 lg:-ml-[1rem] lg:p-3 lg:pl-9 lg:pr-9 lg:rounded-full lg:mt-9">
                Sign up today
              </button>
            </Link>
            <button className="text-white hover:text-indigo-900 border border-white p-3 pl-9 pr-9 rounded-full mt-[2rem] hover:bg-slate-200 md:p-3 md:pl-9 md:pr-9 md:rounded-full md:mt-9 md:-ml-[23rem] md:hover:bg-slate-200 lg:p-3 lg:pl-9 lg:pr-9 lg:rounded-full lg:mt-9 lg:ml-4 lg:hover:bg-slate-200" onClick={moveToDemo}>
              Get a Demo
            </button>
          </div>
        </div>
        <div className="md:mt-20 sm:pl-5 w-3/7 md:w-2/2 md:p-0 md:pl-9 lg:w-2/2 xl:p-0 xl:pl-9 sm:mt-10">
          <img
            src={Dashboard}
            alt="token talent dashboard"
            className="max-h-26 md:max-h-110 lg:max-h-106 lg:h-[27rem] xl:h-[44rem] xl:w-[80rem]"
          />
        </div>
      </div>
    </div>
  );
}

export default Landing;
