import { SendEmailUrl } from "../url";

export async function SendEmailOTP(Email) {
  const url = SendEmailUrl(Email);
  const response = await fetch(url, {
    method: 'GET',
  });

  if (response.ok) {
    const data = await response.json();


    if (data.status === "200") {
      const otp = data["OTP"];
      return { otp, status: true };
   
    } else if (data.status === "400") {
      const msg = data['msg'];
      return { msg, status: false }; // Return an object with msg and status
    }
  } else {
    return { msg: 'Something went wrong. Try again leter', status: false }; // You can customize the message for network errors
  }
}
