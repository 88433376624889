import React from 'react'

function Success() {
    const OpenLoginPage = () => {
        // Define the URL you want to open in the same page
        const newURL = 'https://app.tokentalent.co/';
    
        // Change the current page's URL
        window.location.href = newURL;
      };
  return (
    <div>
      <div className="flex justify-center items-center">
  <div className="p-7 rounded-lg text-center">
    <p className="font-bold text-5xl">Congratulations 🥳</p>
    <p className="text-xs mt-2">You have Successfully Signed up</p>
    <button className="bg-indigo-700 p-2 rounded-lg mt-5 text-white text-sm hover:bg-indigo-600" onClick={OpenLoginPage}>Sign in to Continue</button>
  </div>
</div>
    </div>
  )
}

export default Success
