import React from "react";
import Logo from "../images/logo512.png";
import { Link } from "react-router-dom";
import Footer from "./footer";

function termservices() {
  return (
    <div className="w-screen overflow-x-hidden">
      <div className=" bg-indigo-900 h-[26rem] ">
        <Link to="/" className="flex items-center  text-white p-10 ">
          <img
            src={Logo}
            className="h-10 mr-3 xl:h-[4rem]"
            alt="Flowbite Logo"
          />
          <span className="self-center text-3xl font-semibold whitespace-nowrap dark:text-indigo-900 xl:text-5xl">
            Token Talent
          </span>
        </Link>
        <h2 className=" mt-10 text-center w-[20rem] text-4xl md:mt-10 md:text-7xl text-white font-bold md:ml-[1.6rem] md:text-left md:w-[46rem] lg:ml-14">
        Token Talent's Terms and Conditions
        </h2>
        <p className="ml-[2.9rem] text-md md:ml-[1.6rem] mt-10 md:text-lg text-indigo-300 lg:ml-[4rem]">
          Last updated: 9 February, 2024
        </p>
      </div>
      <div className="ml-[2rem]  md:text-left  w-[17rem] md:w-[44rem] md:ml-[3rem] mt-10 lg:w-[57rem] lg:mt-[2.5rem] lg:ml-[4rem] 2xl:w-[80rem]">
        <h2 className="font-bold text-3xl mb-2">Introduction</h2>
        <p className="text-lg">
        Welcome to Token Talent! These Terms and Conditions (“Terms”) govern your use of our website, tokentalent.co (referred to as “the Website”). By accessing or using the Website, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please refrain from using our Website.
        </p>
        <h2 className="font-bold text-3xl mb-2">Use of Website</h2>

        <l className="text-lg">
          <li>
          Eligibility: You must be at least 13 years old to use this Website. If you are under 13, please do not use our services.
          </li>
          <li>
          User Account: To access certain features of the Website, you may need to create a user account. You are responsible for maintaining the confidentiality of your account information and are liable for all activities that occur under your account.
          </li>
          <li>
          User Content: You may submit content, including text, images, and other materials (“User Content”) on the Website. By submitting User Content, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, and display the User Content.
          </li>
        </l>

        <h2 className="font-bold text-3xl mb-2 mt-10">
        File and Image Access Permission
        </h2>
        <p className="text-lg">
        Token Talent App may require access to files and images on your device for certain functionalities to operate effectively. This access is essential for features such as uploading important documents and updating profile images within the app. By using the Token Talent App, you consent to granting the necessary permissions for accessing files and images stored on your device.
        </p>
        <h2 className="font-bold text-3xl mb-2 mt-10">
        Location Access Permission
        </h2>
        <p className="text-lg">
        Token Talent App may require access to your device's location for certain functionalities to operate effectively. This access is essential for features such as ensuring employees are inside the office premises before allowing them to mark attendance. By using the Token Talent App, you consent to granting the necessary permissions for accessing your device's location.
        </p>

        <h2 className="font-bold text-3xl mb-2 mt-10">
        Intellectual Property
        </h2 >
        <p className="text-lg">
        Ownership: The Website and its content, including but not limited to text, graphics, logos, images, software, and trademarks, are the property of Token Talent and are protected by intellectual property laws.        </p>
        <li className="text-lg">
          To provide you with the services you requested.
        </li>
        <li className="text-lg">
        The Token Talent App and its contents, including but not limited to text, images, logos, and trademarks, are owned by Token Talent and are protected by intellectual property laws. You may not use, reproduce, or distribute any content from the app without prior authorization.
        </li>
        <li className="text-lg">
        Use Restrictions: You may not reproduce, distribute, modify, transmit, or use any content from the Website without our prior written consent.        </li>
        
        <h2 className="font-bold text-3xl mb-2 mt-10">Disclaimer of Warranties</h2>
        <p className="text-lg">The Website is provided “as is” and “as available” without any warranties of any kind, whether express or implied. We do not warrant that the Website will be error-free, uninterrupted, secure, or meet your requirements.</p>
        
        <h2 className="font-bold text-3xl mb-2 mt-10">Limitation of Liability</h2>
        <p className="text-lg">
        Token Talent, its affiliates, officers, directors, and employees shall not be liable for any indirect, consequential, special, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, arising from your use of the Website.
        </p>
        <h2 className="font-bold text-3xl mb-2 mt-10">Indemnification</h2>
        <p className="text-lg">
        You agree to indemnify and hold Token Talent harmless from any claims, losses, damages, liabilities, costs, and expenses (including attorney’s fees) arising out of your use of the Website or violation of these Terms.
        </p>
        <h2 className="font-bold text-3xl mb-2 mt-10">
        Termination
        </h2>
        <p className="text-lg">
        We reserve the right to terminate or suspend your access to the Website at our discretion, without notice, for any violation of these Terms or for any other reason.
        </p>
        <h2 className="font-bold text-3xl mb-2 mt-10">Governing Law</h2>
        <p className="text-lg mb-20">
        These Terms shall be governed by and construed in accordance with the laws of United States of America, without regard to its conflict of law principles.
        </p>
        <h2 className="font-bold text-3xl mb-2 mt-10">Changes to Terms
</h2>
        <p className="text-lg mb-20">
        We may update these Terms from time to time. The latest version will be posted on the Website with the effective date. Your continued use of the Website following any changes constitutes your acceptance of those changes.        </p>
        <h2 className="font-bold text-3xl mb-2 mt-10">Contact Us</h2>
        <p className="text-lg mb-20">
        If you have any questions, concerns, or requests regarding these Terms, please contact us at info@tokentalent.co        </p>
      </div>
      <Footer />
    </div>
  );
}

export default termservices;
