import React from 'react';

import "./App.css";
import Navbar from "./components/navbar";
import Landing from "./components/landing";
import Hero from "./components/hero";
import Management from "./components/management";
import Payroll from "./components/payroll";
import About from "./components/about";
import Footer from "./components/footer";
import Account from "./components/openaccount";
import Productive from "./components/productive";
import Contact from "./components/contact";
import { Link } from 'react-router-dom';
import Reason from './components/Reason';
import { GetBlogs } from './util/getBlogs';
function Home() {
  // const result = GetBlogs();

    return (
      <div className="overflow-x-hidden lg:overflow-x-hidden  w-screen h-screen">
 <div className="relative flex-col flex items-center justify-center bg-[#c9fd02] w-full lg:w-full h-auto md:h-auto">
  <div className="relative flex-row flex h-full max-w-[960px] items-center justify-center  bg-[#c9fd02] rounded-xl max-[479px] px-4 w-[90%] lg:w-full p-4 md:p-4">
    <div className="flex gap-4 max-[991px] max-w-[640px] max-[767px] max-[767px]  max-[479px] mr-0 max-[479px] flex-col items-center sm:flex-row sm:items-center">
      <p className="text-lg text-indigo-900 font-semibold"><span className="font-bold">Join the Founder's Club</span> to get <span className="font-bold text-red-600">20% Discount.</span></p>
      <Link to="/signup" className="inline-block cursor-pointer border border-solid border-black bg-indigo-900 px-5 py-2 text-center text-sm font-bold text-white transition hover:border-black hover:bg-white hover:text-indigo-900 rounded-full">Get Started</Link>
    </div>
  </div>
</div>
         <Navbar />
        <Landing />
        <Hero />
        <Management />
        <Productive/>
        <Reason/>
        <Payroll />
        <Account/>
        <About />
<Contact/>
        <Footer /> 
          
      </div>
    );
  }
  
  export default Home;
  