export function OrganizationUrl(email, password) {
    const organization = 'https://humshroqg7.execute-api.us-east-1.amazonaws.com/Dev/organization?email='+email+'&password='+password;
    return organization; 
}

export function SendEmailUrl(email) {
    const organization = 'https://0o5y2334o4.execute-api.us-east-1.amazonaws.com/start/email?Email='+email;
    return organization; 
}

export function getPublicUrlID() {
    const organization = '7O7J8unJzgLL7VR4x';
    return organization; 
}
export function getTemplateID() {
    const organization = 'template_0m35u6i';
    return organization; 
}

export function getServiceID() {
    const organization = 'service_to65qde';
    return organization; 
}

export function getStripeVerificationUrl({email}) {
    const organization = 'https://humshroqg7.execute-api.us-east-1.amazonaws.com/Dev/singup?email='+email;
    return organization; 
}

export function signUpUrl() {
    const organization = 'https://humshroqg7.execute-api.us-east-1.amazonaws.com/Dev/singup';
    return organization; 
}
