import React, { useState, useRef, useEffect } from 'react';
import Logo from '../images/logo512.png';
import { Link } from 'react-router-dom';

function Navbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showDropdownOpen, setShowDropdownOpen] = useState(false);
  const [isMobileMode, setIsMobileMode] = useState(false);
  const dropdownRef = useRef(null);
  
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const toggleFirstDropdown = (event) => {
    event.stopPropagation();
    setShowDropdownOpen(!showDropdownOpen);
  };
  const moveToSignUp = () => {
    // Specify the URL you want to open in a new tab
    const urlToOpen = 'https://app.tokentalent.co/';
    // Open the URL in a new tab
    window.open(urlToOpen, '_blank');
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdownOpen(false);
    }
  };

  const handleResize = () => {
    setIsMobileMode(window.innerWidth <= 600);
  };

  useEffect(() => {
    handleResize();
    
    // document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
      // document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className=" w-screen  md:w-screen  bg-indigo-900 lg:w-screen xl:w-screen :w-screen">
      <div className=" md:max-w-screen-2xl lg:max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4  ">
        <Link
          to="/"
          className="flex items-center bg-white text-indigo-900 px-4 py-2 rounded-full 2xl:px-10  2xl:py- 2xl:ml-[5rem] "
        >
          <img src={Logo} className="h-8 mr-3 " alt="Flowbite Logo" />
          <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-indigo-900 2xl:text-52xl ">
            Token Talent
          </span>
        </Link>
        <button
          onClick={toggleDropdown}
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden  focus:outline-none focus:ring-2 bg-white"
          aria-controls="navbar-dropdown"
          aria-expanded={isDropdownOpen}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
          <div
          className={`${
            isDropdownOpen ? 'block' : 'hidden'
          } w-full md:block md:w-auto`}
          id="navbar-dropdown"
        >
          <ul className="flex rounded-2xl flex-col font-medium md:rounded-full lg:rounded-full p-4 md:p-3 md:px-0 mt-4 border mr-190 border-white lg:px-4 bg-white md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white 2xl:py-4 2xl:mr-[10rem] 2xl:px-10">
          <Link to="/features" >

            <li>
              <button
                
                
                className="  block py-2 pl-3 pr-4  rounded text-indigo-900 bg-white md:py-[0rem] md:px-2 md:ml-2  md:border-0 lg:mt-[0rem] lg:py-[0rem] "
              >
                Features
                
              </button>
              </li>
              </Link>
            
            
            <li>
              <Link
                to="/pricing"
                className="block py-2 pl-3 pr-4  rounded text-indigo-900 bg-white md:hover:bg-transparent md:py-[0rem] md:border-0  lg:py-[0rem] lg:mt-[0rem] "
              >
                Pricing
              </Link>
            </li>
            <li>
              <Link
                to=""
                onClick={toggleFirstDropdown}
                className="block py-2 pl-3 pr-4  rounded text-indigo-900 bg-white md:hover:bg-transparent md:py-[0rem] md:border-0  lg:py-[0rem] lg:mt-[0rem] "
              >
                Resource
              </Link>
              <div ref={dropdownRef}>
                {
                  showDropdownOpen && !isMobileMode &&(
                    <div>
{/* <div className='mx-1.5 w-4 h-4 absolute mt-3 bg-indigo-500 rotate-45'>

</div> */}
                       <div className='absolute w-48 mt-5 bg-white rounded-lg shadow-lg p-2'>
                      
                      {/* <li>
                <Link to="/blogs" className="block px-4 py-2 text-indigo-900 hover:bg-indigo-200 hover:rounded-lg">Blogs</Link>
              </li> */}
              <li>
                <Link to="/faqs" className="block px-4 py-2 text-indigo-900 hover:bg-indigo-200 hover:rounded-lg">FAQs</Link>
              </li>
              <li>
                <Link to="/about" className="block px-4 py-2 text-indigo-900 hover:bg-indigo-200 hover:rounded-lg">About Token Talent</Link>
              </li>

                    </div>

                    </div>
                   
                  )
                }
                 {showDropdownOpen && isMobileMode && (
        <ul>
          <li>
            {/* <Link
              to="/blogs"
              className="block px-4 py-2 text-indigo-900 hover:bg-indigo-200 hover:rounded-lg"
            >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blogs
            </Link> */}
          </li>
          <li>
            <Link
              to="/faqs"
              className="block px-4 py-2 text-indigo-900 hover:bg-indigo-200 hover:rounded-lg"
            >
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FAQs
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="block px-4 py-2 text-indigo-900 hover:bg-indigo-200 hover:rounded-lg"
            >
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;About Token Talent
            </Link>
          </li>
        </ul>
    )}
              </div>
            </li>
            <li>
              <Link
                to=""
              onClick={moveToSignUp}
                className=" block py-2 pl-3 pr-4  rounded text-indigo-900 bg-white  md:py-[0rem] md:hover:bg-transparent md:border-0   lg:px-2 lg:py-[0rem] lg:mt-[0rem] "
              >
                Login
              </Link>
            </li>
            <li>
              <Link
                to="/signup"
                className=" block py-2 pl-3 pr-4 text-white bg-red-700 rounded md:py-[0rem]  md:bg-transparent md:text-red-700 md:p-2 lg:px-0 lg:py-[0rem] lg:mt-[0rem] "
                aria-current="page"
              >
                Get Started
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
