import React from 'react';
import { Link } from 'react-router-dom';
import { IoIosCheckmarkCircle } from "react-icons/io";
import Ess from '../images/token talent ess.gif'

function productive() {
    return ( 
    <div className="">

<div class=" flex items-center justify-center h-full  sm:py-20 sm:px-5 md:py-48 md:px-20  md:ml-20 ">
    <div class=" flex px-6  md:flex-row sm:flex-col gap-28 ">
        <div class="relative  flex flex-col sm:w-full lg:w-2/5 ">
            <h1 class="flex flex-col text-4xl font-black leading-none text-indigo-800 uppercase font-bebas-neue sm:text-6xl sm:mt-0 md:mt-32">
                Token Talent's
                
            </h1>
            <span class="text-4xl sm:text-5xl font-bebas-neue font-bold text-black">
                Employee Self-Service (ESS) Mobile App
                </span>
            <p class="text-sm text-gray-700 sm:text-base mt-10">
            Using Token Talent’s ESS mobile application, employees and managers can stay connected wherever they go. They will also have access to salary slips, leave and vacation approvals and requests, automated time and attendance tracking, and work schedules and shifts all at their fingertips.
            </p>
            <div className='flex items-center gap-1  mt-10'>
            <IoIosCheckmarkCircle className='text-green-500 h-7 w-7' />
            <p className='font-bold text-lg'>Give your employees access to salary slips</p>
            </div>

            <div className='flex items-center gap-1'>
            <IoIosCheckmarkCircle className='text-green-500 h-7 w-7' />
            <p className='font-bold text-lg'>Automated attendance and time-tracking with geolocation</p>
            </div>

            <div className='flex items-center gap-1'>
            <IoIosCheckmarkCircle className='text-green-500 h-7 w-7' />
            <p className='font-bold text-lg'>Manage employees’s leave and vacation requests</p>
            </div>

            <div className='flex items-center gap-1'>
            <IoIosCheckmarkCircle className='text-green-500 h-7 w-7' />
            <p className='font-bold text-lg'>Employee scheduling and shift management</p>
            </div>

            <div className='flex items-start gap-1'>
            <IoIosCheckmarkCircle className='text-green-500 h-9 w-9' />
            <p className='font-bold text-lg'>Give your employees access to thier calendar so they can see holiday & thier work schedule</p>
            </div>
            <div class="flex mt-20">
              <img src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' alt='playstore icon' className='w-64'/>
               
            </div>
        </div>
        <div class="flex items-end justify-end sm:w-full lg:w-3/5  ">
            <img src={Ess} alt='token talent ess' className=''/>
        </div>
    </div>
</div>

    {/* <div className="sm:px-5 md:px-0">
      <div className="mx-auto w-full max-w-8xl">
        <div className="py-16 md:py-24 lg:py-32">
          <div className="">
            <div className="">
              <div className="">
                <div className="grid grid-cols-2 max-[991px]:flex max-[991px]:flex-col max-[991px]:gap-y-16 ">
                  <div className="w-full p-4  sm:w-full sm:p-6 md:p-10    items-center justify-center flex flex-col ">
                    <div className="mb-4">
                      <h2 className="text-black text-center sm:text-4xl font-bold md:text-5xl"><span className="text-indigo-900">Developing Habits for Success:
</span> Adopting a Productive Mindset</h2>
                    </div>
                    <div className="mb-12">
                      <div className="flex flex-row items-center gap-x-4 max-[479px]:flex-row max-[479px]:justify-start max-[479px]:gap-y-[0px] sm:items-center">
                        <div className="text-lg text-[#414141]">Plan Ahead</div>
                        <div className="rounded-[50%] bg-[#0e0e0e] text-[#414141] max-[479px]:items-center h-2 w-2"></div>
                        <div className="text-lg text-[#414141]">Stay Focused</div>
                        <div className="rounded-[50%] bg-[#0e0e0e] text-[#414141] max-[479px]:hidden max-[479px]:items-center h-2 w-2"></div>
                        <div className="text-lg text-[#414141] max-[479px]:hidden">Prioritize Tasks



</div>
                      </div>
                    </div>
                    <Link to="/pricing" className="inline-block cursor-pointer border border-solid text-white bg-indigo-900 px-6 py-4 text-center font-bold  transition hover:border-black hover:bg-white hover:text-indigo-900 rounded-full mr-5 md:mr-6 lg:mr-8">See Pricing</Link>
                  </div>
                  <div className="h-[416px]   sm:w-full  ">
                    <img alt="" src="https://assets.website-files.com/64fde6fd0e029101efaf5157/64ff00f9c7010cd285ccec66_Productivity.png" className="mx-auto inline-block h-full   object-cover lg:max-w-[640px] rounded-2xl" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
  </div> );
}

export default productive;