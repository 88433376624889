import React from "react";
import { ImPlay } from "react-icons/im";
import { ImTwitter } from "react-icons/im";
import { ImFacebook } from "react-icons/im";
import { ImLinkedin2 } from "react-icons/im";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { ImGoogle } from "react-icons/im";
import { FaTiktok } from "react-icons/fa";
import { AiTwotoneMail } from "react-icons/ai";
import { MdOutlineEmail } from "react-icons/md";

import { Link } from "react-router-dom";

function footer() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const moveToSignUp = () => {
    // Specify the URL you want to open in a new tab
    const urlToOpen = "https://app.tokentalent.co/";
    // Open the URL in a new tab
    window.open(urlToOpen, "_blank");
  };
  const moveToInstagram = () => {
    // Specify the URL you want to open in a new tab
    const urlToOpen = "https://www.instagram.com/tokentalent/";
    // Open the URL in a new tab
    window.open(urlToOpen, "_blank");
  };
  const movetoyoutube = () => {
    // Specify the URL you want to open in a new tab
    const urlToOpen = "https://www.youtube.com/channel/UCosRk7zGP3T4JMDQEK8RMUg";
    // Open the URL in a new tab
    window.open(urlToOpen, "_blank");
  };
  const movetotiktok = () => {
    // Specify the URL you want to open in a new tab
    const urlToOpen = "https://www.tiktok.com/@tokentalenthr";
    // Open the URL in a new tab
    window.open(urlToOpen, "_blank");
  };
  const moveTofacebook = () => {
    // Specify the URL you want to open in a new tab
    const urlToOpen = "https://www.facebook.com/tokentalent";
    // Open the URL in a new tab
    window.open(urlToOpen, "_blank");
  };
  const moveToTwitter = () => {
    // Specify the URL you want to open in a new tab
    const urlToOpen = "https://twitter.com/TokenTalent";
    // Open the URL in a new tab
    window.open(urlToOpen, "_blank");
  };
  const moveTolinkedin = () => {
    // Specify the URL you want to open in a new tab
    const urlToOpen = "https://www.linkedin.com/company/tokentalent";
    // Open the URL in a new tab
    window.open(urlToOpen, "_blank");
  };

  return (
    <>
      <div className=" w-screen   md:w-screen  2xl:w-screen lg:w-screen">
        <footer class="relative block bg-indigo-900">
          <div class="py-12 md:py-16 lg:py-20 mx-auto w-full max-w-7xl px-5 md:px-10">
            <div class="grid justify-between gap-6 max-[991px]:grid-flow-col max-[991px]:[grid-template:'.'_auto_'.'_auto_/_0.75fr_0.75fr_0.75fr] max-[767px]:gap-y-8 max-[479px]:auto-cols-auto max-[479px]:grid-flow-dense sm:gap-4 lg:gap-10 grid-cols-[auto] sm:grid-cols-2 md:grid-cols-[max-content_auto_auto_auto_0.75fr]">
              <div class="mr-0 flex max-w-[360px] grid-cols-1 flex-col items-start justify-start gap-8 max-[767px]:flex-col lg:mr-10">
                <div class="">
                  <a href="#" class="inline-block max-w-full"></a>
                  <div class=" text-white font-bold mt-4 text-5xl max-[479px]:w-full max-[479px]:max-w-full lg:mt-6 max-[991px]:pt-0 max-[991px]:pb-8">
                    Token Talent
                  </div>
                  <p className="text-blue-100 ml-1 mt-2">
                    Revamp your startup HR, Revamp success
                  </p>
                </div>
                <div class="grid w-full max-w-[386px] grid-flow-col grid-cols-4 gap-3 max-[991px]:mb-8 md:mt-12 -ml-5">
                  <ImFacebook
                    onClick={moveTofacebook}
                    className="w-14 h-6 cursor-pointer text-white md:-ml-1"
                  />
                  <BiLogoInstagramAlt
                    onClick={moveToInstagram}
                    className="w-14 h-6 cursor-pointer  ml-[2rem] md:-ml-2 text-white"
                  />
                  <ImPlay
                    onClick={movetoyoutube}
                    className="w-14 h-6 cursor-pointer ml-[4.5rem] md:-ml-2 text-white"
                  />
                  <ImTwitter
                    onClick={moveToTwitter}
                    className="w-14 h-6 cursor-pointer ml-[10.5rem] md:-ml-2 text-white"
                  />
                  <ImLinkedin2
                    onClick={moveTolinkedin}
                    className="w-14 h-6 cursor-pointer  ml-[6.6rem] md:-ml-3 text-white"
                  />

                  <ImGoogle
                    onClick={moveToSignUp}
                    className="w-14 h-6 cursor-pointer ml-[2.2rem] md:-ml-5 text-white"
                  />
                  <FaTiktok
                    onClick={movetotiktok}
                    className="w-14 h-6 cursor-pointer -ml-[1rem] md:-ml-3 text-white"
                  />
                </div>
              </div>
              <div class="flex flex-col items-start font-semibold no-underline">
                <div class="mb-4">
                  <div class="font-bold uppercase text-gray-300">
                    Token Talent
                  </div>
                </div>
                <Link
                  to="/features"
                  class="py-2 text-lg font-normal text-white transition hover:"
                  onClick={scrollToTop}
                >
                  Why Token Talent
                </Link>
                <Link
                  to="/pricing"
                  class="py-2 text-lg font-normal text-white transition hover:"
                  onClick={scrollToTop}
                >
                  Pricing
                </Link>
                <Link
                  to="/hiring"
                  class="py-2 text-lg font-normal text-white transition hover:"
                  onClick={scrollToTop}

                >
                  We're Hiring!
                </Link>
              </div>
              <div class="flex flex-col items-start font-semibold no-underline">
                <div class="mb-4">
                  <div class="font-bold uppercase text-gray-300">Accounts</div>
                </div>
                <Link
                  to=""
                  onClick={moveToSignUp}
                  class="py-2 text-lg font-normal text-white transition hover:"
                >
                  Login
                </Link>
                <Link
                  to="/signup"
                  class="py-2 text-lg font-normal text-white transition hover:"
                >
                  Sign Up
                </Link>
              </div>
              <div class="flex flex-col items-start font-semibold no-underline">
                <div class="mb-4">
                  <div class="font-bold uppercase text-gray-300">Company</div>
                </div>
                <Link
                  to="/termsandCondition"
                  class="py-2 text-lg font-normal text-white transition hover:"
                  onClick={scrollToTop}
                >
                  Terms and Services
                </Link>
                <Link
                  to="/privacypolicy"
                  class="py-2 text-lg font-normal text-white transition hover:"
                  onClick={scrollToTop}
                >
                  Privacy Policy
                </Link>
              </div>
              <div class="hidden md:block  flex-col items-start">
                <div class="mb-4 flex max-w-[272px] items-start justify-start">
                  <div class="mr-4">
                    < MdOutlineEmail className="h-7 w-7 text-white"/>

                  </div>
                  <p class="text-lg text-white">info@tokentalent.co</p>
                
                </div>
                <p class="text-lg text-white">Trusted By</p>
                <div>
                <div className="mt-2">
  <a href="https://businessfirms.co/company/" target="_blank">
    <img
      height="50"
      width="50"
      alt="company widget"
      src="https://businessfirms.co/images/bfirms-certified.png"
    />
  </a>
</div>

                </div>
                
              </div>
            </div>
            <div class="absolute bottom-[auto] left-[0%] right-[auto] top-[0%] -z-[1] inline-block flex-col items-start opacity-70">
              <img
                src="https://assets.website-files.com/64fb4d49d44af6ffd057ea86/64fde115fe33424639970067_Ellipsis.svg"
                alt=""
                class="inline-block"
              />
            </div>
          </div>
        </footer>
      </div>


      <section class="w-[100%] bg-indigo-800">
    <div class="w-[100%] px-3 text-white flex flex-col md:flex-row py-5 text-[13px] md:px-5">
        <div class="mx-auto text-center md:text-start mb-4 md:mb-0">
            <div class="flex flex-row text-[15px] justify-center md:justify-start">
                <p class="text-[15px] font-medium">Token Talent, Pakistan</p>
                <img src="https://images.emojiterra.com/twitter/v13.1/512px/1f1f5-1f1f0.png" alt="pakistan flag" class="max-w-[20px] h-[21px] ml-[5px] mt-[1px]" />
            </div>
            <p class="pt-[5px] md:text-left">Arfa Karim Software Technology Park, Lahore, Punjab Pakistan</p>
        </div>
        <div class="mx-auto text-center md:text-start">
            <div class="flex flex-row justify-center md:justify-start">
                <p class="text-[15px] font-medium">Token Talent, United Kingdom</p>
                <img src="https://images.emojiterra.com/twitter/v13.1/512px/1f1ec-1f1e7.png" alt="england flag" class="max-w-[20px] h-[21px] ml-[5px] mt-[1px]" />
            </div>
            <p class="pt-[5px] md:text-left">13 Lytchett House, Freeland Park, Wareham road, Poole, Dorset, United Kingdom</p>
        </div>
    </div>
</section>

      <div className="flex text-center py-2 text-indigo-900 items-center justify-center">
        Made in
        <img src="https://images.emojiterra.com/twitter/v13.1/512px/1f1f5-1f1f0.png" alt="pakistan flag" class="max-w-[17px] h-[19px] mr-[5px] ml-[5px] mt-[1px]"/>
        With 
        <img src="https://i.pinimg.com/originals/c2/d5/3b/c2d53bc58a8b303f943340f3b14a4f03.png" alt="heart icon" class="max-w-[17px] h-[17px] ml-[5px] mr-[5px] mt-[4px]"/> For 
        <img src="https://cdn-icons-png.flaticon.com/512/9985/9985721.png" alt="heart icon" class="max-w-[20px] h-[20px] ml-[5px] mr-[5px]"/>
        
      </div>
    </>
  );
}

export default footer;
