import React, { useState } from 'react';
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { SendEmailOTP } from '../../util/SendEmail';

function StepOne() {
  const [companyName, setCompanyName] = useState("");
  const [errorMsg, seterrorMsg] = useState(null);
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPassword, setCompanyPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false); // Move state here
  const [otp, setOTP] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [passwordValid, setPasswordValid] = useState(true);
  const validatePassword = (password) => {
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /\d/;
  
    const isLowercase = lowercaseRegex.test(password);
    const isUppercase = uppercaseRegex.test(password);
    const hasNumber = numberRegex.test(password);
    const isMinLength = password.length >= 10;
  
    const isValid =
      isLowercase && isUppercase && hasNumber && isMinLength;
  
    setPasswordValid(isValid);
  };
  


  const sendEmail = async () => {
    const result = await SendEmailOTP(companyEmail);
  
  if (result.status === true) {
    setOTP(result.otp);
    setLoading(false);
    navigate("/signup/otp", {
      state: {
        name: companyName,
        email: companyEmail,
        password: companyPassword,
        otp: result.otp,
      },
    });
  } else {
    seterrorMsg(result.msg)
    // console.error(result.msg); // Log or handle the error message
    setLoading(false);
  }
  }

  const navigateToOtherPage = ()=>{
    console.log(otp)
      

  }
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleSubmit = (e)=>{
    e.preventDefault();
    setLoading(true);
    sendEmail();

  }
  const steps = [
    { index: 1, description: "Company Info" },
    { index: 2, description: "OTP Verification" },
    { index: 3, description: "Subscription Selection" },
    { index: 4, description: "Location Details" },
    { index: 5, description: "Additional Info" }
  ];
  return (
    <div className=' '>
      <div className='flex min-h-full flex-1 flex-col justify-center px-6 pt-[3rem] p-[2rem] -ml-[36rem] md:p-0 md:ml-0 lg:ml-0 md:py-12 lg:px-8 '>
      <div className="hidden md:flex items-center justify-center mb-7">
        {steps.map((step, index) => (
          <div key={step.index} className="relative">
            <div className={`h-10 w-10 flex items-center justify-center rounded-full border-2 mx-2 border-indigo-900 ${
              step.index === 1 ? 'bg-indigo-900 text-white' : 'text-indigo-900'
            }`}>
              {step.index}
            </div>
            <p className={`text-indigo-900 mt-2 text-sm mx-2  ${step.index === 1 && 'font-bold'}`}>{step.description}</p>
            {index !== steps.length - 1 && (
              <div className="absolute top-5 left-14 h-px w-14 bg-indigo-900 mx-3"></div>
            )}
          </div>
        ))}
      </div>
        <div className="mt-[0rem] md:mt- sm:mx-auto sm:w-full sm:max-w-sm ">
          
        <form onSubmit={ handleSubmit }>
            <div>
              <label
                htmlFor="email"
                className="ml-[20.5rem] w-[10rem] md:ml-0 block text-sm md:text-sm font-medium leading-6 text-indigo-900"
              ></label>
              <div className="mt-2">
                <input
                  placeholder="Full legal name of the company"
                  id="Name"
                  name="Name"
                  type="Name"
                  autoComplete="Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                  className="block  ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border border-gray-300 py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-indigo-900  sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="ml-[20.5rem] w-[10rem] md:ml-0 block text-sm md:text-sm font-medium leading-6 text-indigo-900"
              ></label>
              <div className="mt-2 ">
                <input
                  placeholder="Company Email"
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={companyEmail}
                  onChange={(e)=> setCompanyEmail(e.target.value)}
                  className="block  ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border border-gray-300 py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-indigo-900 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between ">
                <label
                  htmlFor="password"
                  className="ml-[20.5rem]  md:ml-0 block text-sm md:text-sm font-medium leading-6 text-indigo-900"
                ></label>
              </div>
              <div>
                <div className="mt-2 relative">
                <input
  placeholder="Password"
  id="password"
  name="password"
  type={passwordVisible ? "text" : "password"}
  autoComplete="current-password"
  required
  value={companyPassword}
  onChange={(e) => {
    setCompanyPassword(e.target.value);
    validatePassword(e.target.value);
  }}
  className={`block ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border ${
    passwordValid ? "border-gray-300" : "ring ring-red-500"
  } py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-indigo-900 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
  // className={`block ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border ${
  //   passwordValid ? "border-gray-300" : "ring ring-inset border-red-500 "
  // } py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-indigo-900 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
/>

                  <div
                    className="absolute inset-y-0  right-[-14.5rem]  md:right-0  md:pr-3   flex items-center cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {passwordVisible ? (
                      <FiEye className="h-10 w-5 text-gray-400" />
                    ) : (
                      <AiOutlineEyeInvisible className="h-10 w-6 text-gray-400" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <p className='mt-2 text-xs text-red-600'>{errorMsg}</p>
          
          <div className="flex ml-[20.5rem] md:ml-0">
            <l className="  mt-10 text-left text-sm  md:text-sm text-gray-500 w-[10rem] md:w-[20rem]">
              <li className="w-[10rem] md:w-full">Lowercase characters.</li>
              <li>Numbers.</li>
            </l>
            <l className="ml-[0rem] md:ml-0 mt-10 text-left text-sm  md:text-sm text-gray-500 w-[10rem] md:w-[20rem]">
              <li className="   w-[10rem] md:w-full">Uppercase characters.</li>
              <li>10 characters minimum.</li>
            </l>
          </div>
          <p className="w-[20rem] md:w-full ml-[20rem] md:ml-0 mt-10 text-center md:text-sm text-gray-500 ">
            Already have an account?&nbsp;
            <Link
              to="/login"
              className="font-semibold leading-6 text-indigo-900 hover:text-black"
            >
              Log in{" "} 
            </Link>
          </p>
          <div className=" flex flex-col items-center justify-center  w-[19rem] md:w-full ml-[20rem] md:ml-0 mt-10 text-center ">
            {/* here */}
            {/* w-[20rem] md:w-full ml-[20rem] md:ml-0 mt-10 text-center md:text-sm */}
          <button
              type="submit"
                className={`transition-all duration-400 ease-in-out ml-3  ${
                  loading
                    ? 'w-14 h-14 bg-indigo-900 text-white rounded-full'
                    : 'mt-2  w-[19rem] md:w-[11.5rem] flex py-3 px-[3rem] md:px-[0rem] md:py-3 justify-center rounded-md bg-indigo-900 text-sm md:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:none md:flex-none md:-mt-[0rem] md:ml-0 lg:ml-2'
                } hover:bg-indigo-700`}
                // onClick={MoveTODashboard}
                disabled={loading || !passwordValid}
              >
                {loading ? (
                  <div className=" animate-spin flex items-center justify-center ">
                    <svg
                      className="w-6 h-6 text-white "
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                     
                  <circle
                    className='opacity-25 '
                    cx='12'
                    cy='12'
                    r='10'
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>

                    </svg>
                  </div>
                ) : (
                  'Continue'
                )}
              </button>
              </div>
              </form>
        </div>
      </div>
    </div>
  );
}

export default StepOne;
