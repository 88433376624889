import React from 'react'
import Navbar from '../navbar';
import Footer from '../footer';


function Blogs({ blogs }) {
  return (
    <>
    <Navbar />
    <div className='bg-indigo-50'>
       <div className='sm:p-32 md:p-72 '>
        here
       </div>
       <div className='sm:px-5 md:px-10 lg:px-72'>
        <h1 className='font-bold sm:text-6xl md:text-8xl text-black'>Recents</h1>
       </div>
       <div className='my-10  sm:px-32 md:px-72 '>

       <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 ">
      {blogs.map((blog, index) => (
        <div key={index} className="bg-white rounded-lg shadow-md p-6 w-5/6">
          <h2 className="text-xl font-bold mb-2">{blog.title}</h2>
          <p className="text-gray-600">{blog.excerpt}</p>
          <a href={blog.url} className="text-blue-500 mt-2 inline-block">
            Read more
          </a>
        </div>
      ))}
    </div>
        </div>
    </div>
    <Footer />
   </>
  )
}

export default Blogs
