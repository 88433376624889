import React, { useState, useEffect } from 'react';
import img1 from '../images/1.png';
import img2 from '../images/2.png';
import img3 from '../images/3.png';

import { Link } from 'react-router-dom';

const imagesAndTexts = [
  {
    src: img1,
    name:'Alice Marto',
    text: '“We work with 460+ contractors. I’d need to employ 5 or 6 people full time to keep on top of compliance, manage individual invoices, work with accountancy firms and make payments in multiple currencies. Token Talent has definitely been a timesaver and a money saver for us.”',
  },
  {
    src: img2,
    name:'Olivia Jade',
    text: '“We wanted to start fresh and offer our employees a better experience. We aligned best with Token Talent in respect to the support we were given and the fast turnaround: in just a few weeks our entire Romania workforce was all set up and ready to go, with India following a few months later.”',
  },
  {
    src: img3,
    name:'Zoe Pearl',
    text: '“The Token Talent team works like magic: they are hands-on, proactive and we are always connected with the right people to get the solutions we need very quickly.”',
  },
  // Add more items here
];

function About() {

  const [currentIndex, setCurrentIndex] = useState(0);
  const currentImageAndText = imagesAndTexts[currentIndex];

 
  useEffect(() => {
    // Automatically switch to the next item every 5 seconds
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesAndTexts.length);
    }, 3000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);


  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesAndTexts.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imagesAndTexts.length - 1 : prevIndex - 1
    );
  };

  return (
    <>
    <div className='w-screen   md:w-screen  bg-indigo-900 mt-0 h-[48rem] lg:w-screen 2xl:w-screen'>
      <div className='text-center pt-36'>
      <div className="flex flex-col items-center justify-center">
      <div className="flex space-x-5">
        <img
          alt=""
          className="w-42 h-48 rounded-full ri ri dark:bg-gray-500 ri ri"
          src={currentImageAndText.src}
        />
      </div>
        </div>
        <h2 className='font-bold text-white mt-10 -mb-10  mx-auto w-[20rem] md:w-[40rem] lg:w-[50rem] text-2xl  md:text-2xl lg:text-2xl'>{currentImageAndText.name}</h2>

        <p className='text-white mt-20  mx-auto w-[20rem] md:w-[40rem] lg:w-[50rem]  lg:text-12xl md:text-xl lg:text-xl'>
          {currentImageAndText.text}
        </p>
        <button
          className='bg-white p-3 pl-9 pr-9 rounded-full mt-9 hover:bg-slate-200'
          onClick={handlePrevious}
        >
          Previous
        </button>
        <button
          className='bg-white p-3 pl-9 pr-9 rounded-full mt-9 ml-4 hover:bg-slate-200'
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </div>
    
        <div className="px-5 md:px-10">
      <div className="mx-auto w-full max-w-7xl">
        <div className="py-12 md:py-16 lg:py-20">
          <div className="flex-col flex items-stretch gap-[70px]">
            <div className="flex-col flex items-center gap-5 text-center">
              <h2 className="text-indigo-900 font-bold text-3xl md:text-5xl">Grow further with Token Talent</h2>
              <Link to="/pricing">
          <button className="bg-indigo-900 px-6 py-3 text-white rounded-full font-semibold hover:bg-indigo-500 mt-3">
            Show all Plans
          </button>
          </Link>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  
    </>
  );
}

export default About;
