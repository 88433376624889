
import { getStripeVerificationUrl } from "../url";

export async function fetchPaymentDetails  ({email}) {
    const url = getStripeVerificationUrl({email: email});
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          // console.log(data)
          const status = data['body']['payment status']
          return status;
        } else {
        }
      } catch (error) {
        console.error('Error fetching details:', error);
      } 
    };