import * as React from 'react';

function PricingPage({email, id}) {
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <stripe-pricing-table
      pricing-table-id="prctbl_1O9rxuCNn2Z3Aarn7oouB01g"
      publishable-key="pk_live_51O2FIcCNn2Z3AarnuwnueEnNvaifjstX0AAnPB8c9ncNpdo79B0QLHx34jhMONjQbFR5HynIRnNpJJfFYI0g0KHF006RA3It4W"
      customer-email={email}
      client-reference-id={id}
    >
    </stripe-pricing-table>
  );
}

export default PricingPage;