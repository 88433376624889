import React from 'react'
import Navbar from '../navbar';
import Footer from '../footer';
import { Link } from 'react-router-dom';

function MYAbout() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const moveToDemo = () => {
    const urlToOpen = 'https://calendly.com/demofortokentalent/token-talent-demo';
    window.open(urlToOpen, '_blank');
  };
  const featureItems = [
    {
      title: 'Support',
      image: 'https://assets.website-files.com/63904f663019b0d8edf8d57c/639157f1a197859a6cd7f265_image%203.png',
      description: 'At our core, we prioritize support. We believe in standing by our customers every step of the way, offering reliable assistance and guidance to ensure their success.',
    },
    {
      title: 'Organise',
      image: 'https://assets.website-files.com/63904f663019b0d8edf8d57c/63915859fa889834c4f1ff92_image%203-2.png',
      description: 'Organization is key to our operations. We strive for efficiency and clarity in everything we do, enabling smooth workflows and streamlined processes.',
    },
    {
      title: 'Flexibility',
      image: 'https://assets.website-files.com/63904f663019b0d8edf8d57c/639158510667812dff08e1af_image%203-4.png',
      description: 'We embrace flexibility as a fundamental principle. Adapting to changing circumstances with agility allows us to innovate, grow, and stay ahead in a dynamic environment.',
    },
    {
      title: 'Speed',
      image: 'https://assets.website-files.com/63904f663019b0d8edf8d57c/6391585b7b7cd87baef5e9ec_image%203-1.png',
      description: 'Speed is part of our DNA. We move quickly and decisively, delivering prompt solutions and responses to meet the evolving needs of our customers and stakeholders.',
    },
    {
      title: 'Quality',
      image: 'https://assets.website-files.com/63904f663019b0d8edf8d57c/639158557ac2b528531836f1_image%203-3.png',
      description: 'Quality is non-negotiable for us. We are committed to excellence in every aspect of our work, ensuring the highest standards of products, services, and experiences.',
    },
    {
      title: 'Resource',
      image: 'https://assets.website-files.com/63904f663019b0d8edf8d57c/639157f3db4f4b8767c499ba_image%203-5.png',
      description: 'We value our resources and maximize their potential responsibly. By optimizing efficiency and minimizing waste, we strive for sustainable growth and lasting impact.',
    },
  ];
  
  
  
  return (
    <>
    <Navbar />
    <div>
        <div className='sm:p-10 md:p-36 flex flex-col item'>
        <div className="bg-indigo-900 rounded-2xl shadow-lg shadow-indigo-500 relative transform preserve-3d">
        {/* Container */}
        <div className="mx-auto max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
          {/* Title */}
          <div className='mb-6 max-w-3xl lg:mb-12 text-white bg-indigo-500 p-5 rounded-lg'>
          <h1 className="text-4xl font-bold md:mb-10 md:text-6xl ">Unlocking HR's Full Potential with Token Talent</h1>
          <h2 className='text-xl font-bold md:text-2xl '>Looking to take your HR employees to a state of Token Talent?</h2>

          </div>
          
          {/* Buttons */}
          <div className="flex items-stretch">
            <Link to="/signup">
            <button  className="mr-6 rounded-md bg-indigo-700 hover:bg-indigo-500 px-8 py-4 text-center font-semibold text-white lg:mr-8"> Get Started</button>
            </Link>

           
            <button className="flex items-center justify-center rounded-md border border-solid border-indigo-700 bg-white px-6 py-3 font-bold" onClick={moveToDemo}>
              Let's Meet
            </button>

           
            
            
          </div>
        </div>
      </div>
      {/* Hero bottom */}
      <div className="mx-auto max-w-7xl px-5 py-12 md:px-10 md:py-16 lg:py-20">
        {/* Component */}
        <div className="relative flex max-w-7xl flex-col gap-4 lg:flex-row lg:justify-end">
         
          <div className="max-w-xl lg:mr-[500px] lg:max-w-xs">
            {/* Title */}
            <h3 className="text-2xl font-bold md:text-3xl">About US</h3>
            {/* Divider */}
            <div className="my-6 w-16 border-t-2 border-solid border-black"></div>
            <p className="text-md text-[#636262]">At Token Talent, our mission is to empower HR departments and employees with a revolutionary HR platform designed to bring tranquility to the workplace. Unlike conventional HR software, Token Talent offers an immersive HR experience tailored to the needs of businesses and employees. With localized features covering labor laws, expat regulations, social insurance, and tax laws, Token Talent caters to SMEs and Enterprises across the MENA region.

Our cloud-based HRMS revolutionizes HR operations with automated salary calculations and Employee Self Service (ESS) features, placing employees at the forefront and streamlining processes for all. From onboarding to retirement, Token Talent ensures a seamless journey, prioritizing customer satisfaction at every stage.

Driven by our core values, Token Talent is committed to exceeding customer expectations and enhancing the employee experience. We remain dedicated to staying abreast of the latest HR trends, empowering organizations to make informed decisions and optimize their workforce effectively.</p>
          </div>
          {/* Image */}
          <img src="https://images.unsplash.com/photo-1541746972996-4e0b0f43e02a?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="token talent office" className="relative bottom-0 right-0 mt-12 w-[480px] object-cover lg:absolute lg:mt-0 lg:h-[480px] rounded-lg" />
        </div>
      </div>

      {/* values */}
      <div className='flex justify-center' >
      <h1 className="mb-6 max-w-3xl text-4xl font-bold md:mb-10 md:text-6xl lg:mb-12 text-white bg-indigo-500 p-5 rounded-lg">Our Core Values</h1>

      </div>
      

      <section>
      {/* Container */}
      <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-16 lg:py-16 ">
        {/* Component */}
        <div className="grid grid-cols-1 items-center gap-12 sm:gap-20 lg:grid-cols-2">
          {/* Image Div */}
          <div className="relative left-4 h-full max-h-[560px] w-[85%] overflow-visible md:left-0 md:w-[95%] lg:w-full">
            <img
              src="https://images.unsplash.com/photo-1507207611509-ec012433ff52?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="token talent office"
              className="z-10 mx-auto block h-full w-full max-w-[800px] rotate-[3.5deg] rounded-2xl object-cover"
            />
            <div className="absolute bottom-0 left-[-16px] right-0 top-4 -z-10 h-full w-full rounded-2xl bg-indigo-500 shadow-lg shadow-indigo-500"></div>
          </div>
          {/* Features Div */}
          <div className="md:mx-w-auto grid h-full w-full max-w-[720px] gap-2 sm:grid-cols-2 md:gap-8">
            {/* Feature Items */}
            {featureItems.map((item, index) => (
              <div key={index} className="flex flex-col p-2">
                <div className="relative mb-4 flex h-14 w-14 flex-col items-center justify-center rounded-full border border-solid border-[#9b9b9b] [box-shadow:rgb(0,_0,_0)_0px_3px]">
                  <img src={item.image} alt="" className="relative z-[1] inline-block h-8" />
                  <div className="absolute z-0 h-8 w-8 rounded-full border border-indigo-300 bg-indigo-300"></div>
                </div>
                <p className="mb-4 font-semibold">{item.title}</p>
                <p className="text-sm text-[#636262]">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>


    {/* join the team */}
    <section>
      {/* Features Container */}
      <div className="mx-auto max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
        {/* Features Title */}
        <div className="text-center">
          <h2 className="text-3xl font-bold md:text-5xl text-indigo-900">Join the Token Talent's Team</h2>
          <p className="mx-auto mb-8 mt-4 max-w-lg text-[#647084] md:mb-12 lg:mb-16">We are a group of dynamic and passionate people dedicated to providing cutting-edge technology while continuously researching and implementing new HR trends. If you really want to make a difference in the HR world, then Token Talent is the place for you.</p>
        </div>
        {/* Features Grid */}
        <div className="grid gap-5 sm:grid-cols-2 md:grid-cols-3">
          {/* Features Item */}
          <div className="grid gap-8 p-8 md:p-10 bg-indigo-200 rounded-lg hover:bg-indigo-400">
            <img src="https://assets.website-files.com/6357722e2a5f19121d37f84d/6358bda43ea08a612343b1f3_Vector-3.svg" alt="" className="inline-block h-8" />
            <p className="text-xl font-semibold">Flexible Workplace</p>
            <p className="text-sm text-[#636262]">We're strong believers that what matters most are results.</p>
          </div>
          {/* Features Item */}
          <div className="grid gap-8 p-8 md:p-10 bg-indigo-200 rounded-lg hover:bg-indigo-400">
            <img src="https://assets.website-files.com/6357722e2a5f19121d37f84d/6358bdb1bd05f5915d7bf31c_Vector-4.svg" alt="" className="inline-block h-8" />
            <p className="text-xl font-semibold">Learn From the Best</p>
            <p className="text-sm text-[#636262]">Work with some of the brightest minds in the industry.</p>
          </div>
          {/* Features Item */}
          <div className="grid gap-8 p-8 md:p-10 bg-indigo-200 rounded-lg hover:bg-indigo-400">
            <img src="https://assets.website-files.com/6357722e2a5f19121d37f84d/6358bdaaeeb5cbd611bf5048_Vector-5.svg" alt="" className="inline-block h-8" />
            <p className="text-xl font-semibold">Employee Benefits</p>
            <p className="text-sm text-[#636262]">Enjoy a full range of employee benefits and perks.</p>
          </div>
        </div>
      </div>
    </section>

    <div  className='flex justify-center'>
      <Link to="/hiring">
      <button className="mb-6 max-w-3xl text-4xl font-bold md:mb-10 md:text-2xl lg:mb-12 text-white bg-indigo-500 p-5 rounded-lg hover:bg-indigo-700 shadow-lg shadow-indigo-500" onClick={scrollToTop}>We're Hiring</button>
      </Link>


    </div>

        </div>
    </div >
    <Footer /> 
   </>
  )
}

export default MYAbout
