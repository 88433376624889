import React from 'react';
import Logo from '../images/logo512.png';
import { Link } from 'react-router-dom';
import Footer from "./footer";

function privacypolicy() {
    return ( 
        <div className="w-screen overflow-x-hidden">
    <div className=' bg-indigo-900 h-[26rem] '>
    <Link
          to="/"
          className="flex items-center  text-white p-10 "
        >
          <img src={Logo} className="h-10 mr-3 xl:h-[4rem]" alt="Flowbite Logo" />
          <span className="self-center text-3xl font-semibold whitespace-nowrap dark:text-indigo-900 xl:text-5xl">
            Token Talent
          </span>
        </Link>
        <h2 className=' mt-10 text-center w-[20rem] text-4xl md:mt-10 md:text-7xl text-white font-bold md:ml-[4.6rem] md:w-[30rem] lg:ml-14'>Token Talent's
Privacy Policy</h2>
<p className="ml-[2.9rem] text-md md:ml-[4.6rem] mt-10 md:text-lg text-indigo-300 lg:ml-[4rem]">Last updated: September 1, 2023</p>
    </div>
    <div className="ml-[3rem]  md:text-left  w-[17rem] md:w-[44rem] md:ml-[3rem] mt-10 lg:w-[57rem] lg:mt-[2.5rem] lg:ml-[4rem] 2xl:w-[80rem]">
<h2 className="font-bold text-3xl mb-2">Introduction</h2>
<p className="text-lg">Welcome to Token Talent! At Token Talent, we are committed to protecting your privacy and providing you with a secure online experience. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you visit our website, tokentalent.co (referred to as “the Website”). By accessing or using the Website, you agree to the practices described in this Privacy Policy.
Information We Collect</p>
<l className="text-lg">
    <li>Personal Information: We may collect personal information, such as your name, email address, phone number, and other contact details, when you sign up for our services, subscribe to newsletters, or contact us.</li>
<li>Usage Information: We may collect information about how you use the Website, including your IP address, browser type, pages visited, and the time and date of your visits.</li>
<li>Cookies and Tracking Technologies: We use cookies and similar tracking technologies to enhance your browsing experience and gather data about your interactions with our Website. You can manage your cookie preferences through your browser settings.</li>
</l>

<h2 className="font-bold text-3xl mb-2 mt-10">How We Use Your Information</h2>
<p className="text-lg">We may use your information for the following purposes:
</p>
<li className="text-lg">To provide you with the services you requested.
</li>
<li className="text-lg">To improve and personalize your experience on the Website.

</li>
<li className="text-lg">To communicate with you, including responding to your inquiries and sending you updates.

</li>
<li className="text-lg">To analyze and monitor the usage of our Website.

</li>
<li className="text-lg">To protect the security and integrity of our Website.
</li>
<h2 className="font-bold text-3xl mb-2 mt-10">Your Choices
</h2>
<p className="text-lg">You have the right to:
</p>
<li className="text-lg">Access and Update: Access, correct, or update your personal information by logging into your account or contacting us directly.
</li>
<li className="text-lg">Unsubscribe: Opt-out of receiving marketing communications from us by following the instructions provided in our emails or contacting us.
</li>
<h2 className="font-bold text-3xl mb-2 mt-10">
Security
</h2>
<p className="text-lg">
We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or electronic storage is entirely secure. Therefore, we cannot guarantee absolute security.
</p>
<h2 className="font-bold text-3xl mb-2 mt-10">
Children’s Privacy
</h2>
<p className="text-lg">
Our Website is not intended for children under the age of 13. We do not knowingly collect or maintain personal information from children.
</p>
<h2 className="font-bold text-3xl mb-2 mt-10">
Changes to this Privacy Policy
</h2>
<p className="text-lg">
We may update this Privacy Policy from time to time. The latest version will be posted on the Website with the effective date. Your continued use of the Website following any changes constitutes your acceptance of those changes.
</p>
<h2 className="font-bold text-3xl mb-2 mt-10">
Contact Us
</h2>
<p className="text-lg mb-20">
If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at cs@tokentalent.co .</p>
    </div>
    <Footer/>
    </div> 
    );
}

export default privacypolicy;